import { Component, OnInit, OnDestroy, Input, forwardRef, ChangeDetectorRef } from '@angular/core';
import { NgControl, FormControl, FormBuilder, FormGroup, FormArray, Validator, Validators, ControlValueAccessor, AbstractControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { tap, map, first } from 'rxjs/operators';
import { MenuItem } from '@interface/menuitem.interface';
import { Image } from '@interface/image.interface';
import { MenuItemService } from '@service/api/menuitem.service';
import { HydraCollection } from '@interface/hydra/collection.interface';

@Component({
  selector: 'form-meal-set',
  templateUrl: './meal-set.component.html',
  styleUrls: ['./meal-set.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormMealSetComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormMealSetComponent),
      multi: true
    }
  ]
})
export class FormMealSetComponent implements ControlValueAccessor, OnInit, OnDestroy, Validator {
  constructor(
    private fb: FormBuilder,
    private menuItemApi: MenuItemService
  ) { }

  @Input() image!: Image;
  @Input() image_required: boolean = false;

  image_max_size:number = 250000;
  image_path: string = '';
  image_width:number = 940;
  image_height:number = 152;
  file_errors = this.resetErrors();
  input_touched: boolean = false;
  values: any = '';

  form: FormGroup = this.fb.group({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(150)
    ]),
    tagline: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(255)
    ]),
    description: new FormControl(null, [
      Validators.minLength(2),
      Validators.maxLength(1024)
    ]),
    displayOrder: new FormControl(this.getDisplayOrder(), [
      Validators.required
    ]),
    mealSetIncludes: [],
    mealSetGroups: this.fb.array([]),
    mealAudienceCategory: null,
    published: new FormControl(false, [
      Validators.required
    ]),
  });


  menuItems:MenuItem[] = [];
  menuItems$:Observable<MenuItem[]> = this.menuItemApi.getAll({'pagination': false}).pipe(
  first(),
  map((data:HydraCollection): MenuItem[] => {
    return data['hydra:member'];
  }),
  map((data:MenuItem[]) => {
    this.menuItems = data;
    return data;
  }));

  onTouched: Function = () => {};
  onChanged: Function = () => {};

  onChangeSubs: Subscription[] = [];

  ngOnInit() {
    if(this.image)
      this.image_path = this.image.jpg;
  }

  ngOnDestroy(): void {
    for (let sub of this.onChangeSubs) {
      sub.unsubscribe();
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeSubs.push(this.form.valueChanges.pipe(map((data) => {
      if(!data.mealSetIncludes)
        data.mealSetIncludes = [];

      if(data.description === '') {
        data.description = null;
      }
      return data;
    })).subscribe(fn));
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
  getDisplayOrder(): Number {
    const n = new Date();
    return n.getFullYear() + n.getMonth() + n.getDay() + n.getHours() + n.getMinutes() + n.getSeconds();
  }
  writeValue(value: any): void {
    this.values = value;
    if (value) {
      this.buildFormWithData(value);
      this.form.patchValue(value, {emitEvent: false});
    } else {
      this.addGroup();
    }
  }

  buildFormWithData(value:any) {
    if(value.mealSetGroups) {
      for(let i = 0; i < value.mealSetGroups.length; i++) {
        this.addGroup();
        let variants = value.mealSetGroups[i].variants
        if(variants.length) {
          for(let x=0; x < variants.length; x++) {
            this.addVariant(i);
          }
        }

        for(let x = 0; x < value.mealSetGroups[i].mealSetSection.length; x++) {
          this.addMealset(i);
          for(let y = 0; y < value.mealSetGroups[i].mealSetSection[x].sectionItems.length; y++) {
            this.addItem(i, x);
          }
        }
      }
    } else {
      this.addGroup();
    }
  }

  validate(control: AbstractControl) {
    if(this.image_required) {
      if(!this.image_path) {
        this.file_errors.required = true;
        return { valid: false, message: 'Invalid mealSet' };
      }
    }

    return this.form.valid ? null : { valid: false, message: 'Invalid mealSet' };
  }

  isValid(name: string): boolean {

    if(this.form.get(name)) {
      let control = this.form.get(name) as FormControl;
      return control.valid;
    }

    return false;
  }

  isTouched(name: string): boolean {
    if(this.form.get(name)) {
      let control = this.form.get(name) as FormControl;
      return control.dirty;
    }
    return false;
  }

  resetErrors() {
    return {
      image_large: false,
      required: false,
      image_dims: false
    };
  }

  getErrors() {
    let arr = Object.entries(this.file_errors);
    return arr.filter(([key, value]) => value).length
  }

  get mealSetGroups():FormArray {
    return this.form.get('mealSetGroups') as FormArray;
  }

  groupmealSetSection(i:number): FormArray {
    let x = ((this.form.get('mealSetGroups') as FormArray).controls[i] as FormGroup).controls;
    return x.mealSetSection as FormArray;
  }

  addGroup() {
    this.mealSetGroups.push(this.returnGroupForm());
  }

  addMealset(x:number):void {
    ((this.mealSetGroups.controls[x] as FormGroup).controls.mealSetSection as FormArray).push(this.returnSectionForm());
  }

  addItem(x:number, b:number):void {
    let sectionItems = (this.mealSetGroups.controls[x] as FormGroup).controls.mealSetSection as FormArray;
    ((sectionItems.controls[b] as FormGroup).controls.sectionItems as FormArray).push(this.returnMenuItemForm());
  }
  addVariant(x:number):void {
    let tmp = ((this.mealSetGroups.controls[x] as FormGroup).get('variants') as FormArray)
    tmp.push(this.returnVariantForm());
  }
  groupmealSetSectionItems(i:number, b:number): FormArray  {
    let mealSetSection = this.groupmealSetSection(i);
    let x = mealSetSection.controls[b] as FormGroup
    return x.controls.sectionItems as FormArray;
  }

  removeItem(items:FormArray, i:number):void {
    items.removeAt(i);
  }

  getVariants(group:any) {
    let tmp = group.get('variants');
    return tmp;
  }

  removeVariant(i:number, x:number) {
    let variants = ((this.mealSetGroups as FormArray).controls[i] as FormGroup).get('variants') as FormArray;
    variants.removeAt(x);
  }
  returnGroupForm(): FormGroup {
    return this.fb.group({
      name: [''],
      displayOrder: [0, [Validators.required]],
      finePrint: [''],
      type: ['', [Validators.required]],
      price: [0, [Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$")]],
      mealSetSection: this.fb.array([]),
      variants: this.fb.array([]),
      optionTitle: ['', [Validators.maxLength(255)]],
      optionRule: ['', [Validators.maxLength(255)]],
    });
  }

  returnSectionForm(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      displayOrder: [0, [Validators.required]],
      rule: ['', [Validators.minLength(2), Validators.maxLength(255)]],
      finePrint: ['', [Validators.minLength(2), Validators.maxLength(255)]],
      sectionItems: this.fb.array([])
    });
  }

  returnMenuItemForm(): FormGroup {
    return this.fb.group({
      menuItem: ['', [Validators.required]],
      name: [''],
      description: ['', [Validators.minLength(2), Validators.maxLength(255)]],
      displayOrder: [0, [Validators.required]],
      price: [0, [Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$")]]
    });
  }


  returnVariantForm(): FormGroup {
    return this.fb.group({
      name: [null],
      description: [],
      price: [0, [Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$")]]
    });
  }

  moveUp(arr:FormArray, i:number) {
    if(i <= 0) {
      return;
    }

    let iold:number = i;
    let inew:number = --i;

    let tmp = arr.controls[iold]
    arr.controls[iold] = arr.controls[inew];
    arr.controls[inew] = tmp;

    (arr.controls[iold] as FormGroup).controls['displayOrder'].setValue(iold);
    (arr.controls[inew] as FormGroup).controls['displayOrder'].setValue(inew);
  }

  moveDown(arr:FormArray, i:number) {
    if(i >= arr.length) {
      return;
    }

    let iold:number = i;
    let inew:number = ++i;

    let tmp = arr.controls[iold]
    arr.controls[iold] = arr.controls[inew];
    arr.controls[inew] = tmp;

    (arr.controls[iold] as FormGroup).controls['displayOrder'].setValue(iold);
    (arr.controls[inew] as FormGroup).controls['displayOrder'].setValue(inew);

  }

  itemIsSelected(value:any): boolean {
    if(value.get('menuItem').value)
      return true;
    else
      return false;
  }

  isOverrideChecked(value:any): boolean {
    if(value.get('name').value || value.get('description').value)
      return true;
    else
      return false;
  }

  doOverride(event:any, x:number, y:number, z:number):void {
    let tmp = (this.groupmealSetSectionItems(x,y) as FormArray).controls[z] as FormGroup;
    if(event.checked) {

    } else {
      (tmp.get('name') as FormControl).reset();
      (tmp.get('description') as FormControl).reset();
    }
  }

  groupmealSetSectionItem(group:number, section:number, item:number) {
    const g = (this.groupmealSetSectionItems(group, section) as FormArray);
    return g.controls[item];
  }
  setMenuItemSelected(group:number, section:number, item:number, menuItem:any) {
    this.menuItems.push(menuItem);
    const control = this.groupmealSetSectionItem(group, section, item) as FormGroup;
    const mItem = control.get('menuItem')
    if(mItem) {
      mItem.setValue(menuItem['@id'])
    }
  }
  getTypeValue(index:number): string {
    const value = this.form.get('mealSetGroups') as FormArray;
    return (value.controls[index].get('type') as FormControl).value
  }
  getMenuItem(group:number, section:number, item:number) {
    const menuItem = this.groupmealSetSectionItem(group, section, item) as FormGroup;
    return this.menuItems.find((x) => x['@id'] === menuItem.get('menuItem')?.value)
  }
  change(e:any) {
    this.input_touched = true;
    this.file_errors = this.resetErrors();

    let file = e.target.files[0];
    if( file.size > this.image_max_size)
      this.file_errors.image_large=true;

    let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        const image = new Image();
        image.src = _event!.target!.result as string;
        image.onload = () => {
          if(image.width !== this.image_width || image.height !== this.image_height)
            this.file_errors.image_dims = true;

          if(!this.getErrors())
            this.image_path = reader.result as string;
        };
      }
    }
}
