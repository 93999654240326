import { Component, OnInit, OnDestroy, Input, forwardRef, ChangeDetectorRef } from '@angular/core';
import { NgControl, FormControl, FormBuilder, FormGroup, FormArray, Validator, Validators, ControlValueAccessor, AbstractControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { tap, map, first } from 'rxjs/operators';

import { HydraCollection } from '@interface/hydra/collection.interface';
import { MenuAudience } from '@interface/audience.interface';
import { MealAudienceCategory } from '@interface/mealaudiencecategory.interface';
import { AudienceService } from '@service/api/audience.service';


@Component({
  selector: 'form-audience-category-options',
  templateUrl: './audience-category-options.component.html',
  styleUrls: ['./audience-category-options.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormMealAudienceCategoryOptionsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormMealAudienceCategoryOptionsComponent),
      multi: true
    }
  ]
})
export class FormMealAudienceCategoryOptionsComponent implements ControlValueAccessor, Validator {


  constructor(
    private fb: FormBuilder,
    private audienceApi: AudienceService,
  ) {
    /*
    this.onChangeSubs.push(
      /
      this.form.valueChanges.subscribe((value) => {
      //this.onChanged(this.getValues(value.audience));
      //this.onTouched();
      })
    )
    */
  }
  audienceForm:FormControl = new FormControl('', [Validators.required]);
  categoryForm:FormControl =  new FormControl('', [Validators.required]);

  selected: String = '';
  categories: MealAudienceCategory[] = [];
  audiences: MenuAudience[] = [];
  audience$: Observable<MenuAudience[]> = this.audienceApi.getAll({'pagination': false}).pipe(
    first(),
    map((data:HydraCollection): MenuAudience[] => {
      return data['hydra:member'];
    }),
    map((data:MenuAudience[]) => {
      this.audiences = data;
      return data;
    }));

  onTouched: Function = () => {};
  onChanged: Function = () => {};

  onChangeSubs: Subscription[] = [];


  selectedAudience(event:any) {
    this.setupAudience(event.value)
  }
  selectedCategory(event:any) {
    this.setupCategory(event.value);
  }
  setupCategory(value:string) {
    this.selected = value;
    this.categoryForm.setValue(value);
    this.onChanged(this.selected)
  }
  setupAudience(value:string) {
    let audience = this.audiences.find((x:MenuAudience) => x['@id'] == value);
    this.categories = audience!.mealAudienceCategories;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.audienceForm.disable();
      this.categoryForm.disable();
    }
    else {
      this.audienceForm.enable();
      this.categoryForm.enable();
    }
  }

  writeValue(value: any): void {
    if (value) {
      this.audience$.subscribe(data => {
        this.selected = value;
        for(let i:number = 0; i<this.audiences.length; i++) {
          let search = this.audiences[i].mealAudienceCategories.find(x => x['@id'] == value);
          if(search !== undefined) {
            this.audienceForm.setValue(this.audiences[i]['@id']);
            this.setupCategory(search['@id'] as string)
            this.setupAudience(this.audiences[i]['@id'] as string)
            break;
          }
        }
      })
    }
  }

  validate(control: AbstractControl) {
    return this.categoryForm.valid ? null : { message: 'Category is required' };
  }

}
