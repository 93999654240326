<app-breadcrumb></app-breadcrumb>
<ng-container *ngIf="mealSet$ | async">
  <form [formGroup]="form" (submit)=submit()>
    <form-meal-set formControlName="mealSet" [image]="mealSet.image"></form-meal-set>
    <mat-card-actions align="end">
      <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid"><mat-icon aria-hidden="false">save</mat-icon> Update Meal Set</button>
      <button mat-raised-button color="warn" type="button" (click)="openDialog()"><mat-icon aria-hidden="false">delete</mat-icon> Delete Meal Set</button>
    </mat-card-actions>
  </form>
</ng-container>
