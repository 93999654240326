<mat-card>
  <mat-card-title>Users</mat-card-title>
  <mat-card-subtitle>List of all users</mat-card-subtitle>
  <mat-card-content>
    <admin-user-list></admin-user-list>
  </mat-card-content>
  <mat-card-actions align="end">
    <a mat-raised-button color="primary" [routerLink]="['/admin/user/create']">
      <mat-icon aria-hidden="false" aria-label="Edit icon">add_circle</mat-icon>Create User
    </a>
  </mat-card-actions>
</mat-card>
