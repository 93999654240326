import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


import { User } from '@interface/user.interface';
import { LoginService } from '@service/api/login.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    public login: LoginService,
    public router: Router
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.login.whoAmI().pipe(map((data:any) => {
      
      //If they are on the login page && they aren't logged in then allow the guard to pass. 
      if(state.url === '/admin/login' && typeof data === 'boolean' && !data) {
        return true;
      }

      //If they are on the login page && they are already logged in, redirect to admin
      if(state.url === '/admin/login' && this.isAuth(data.roles, route.data.roles)) {
        this.router.navigate(['admin']);
        return true;
      }

      //If we get a true/false statement from the server then process it
      if(typeof data === 'boolean') {
        if(data) {
          this.router.navigate(['admin']);
          return true;
        } else {
          window.location.href = './admin/login';
          return false;
        }
      }
      
      //Check if they have permissions, if yes, redirect and pass the guard. If no, redirect to login and fail the guard. 
      if(this.isAuth(data.roles, route.data.roles))
        return true;
        window.location.href = './admin/login';
        return false;
    }))
  }

  isAuth(userRoles:string[], routerRule:string[]):boolean {
    let valid = false;
    userRoles.every((e:string) => {
      if(routerRule.includes(e)) {
        valid = true;
        return false
      }
      return true;
    });
    return valid;
  }
}
