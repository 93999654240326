<mat-sidenav-container fullscreen>
  <mat-sidenav mode="side" class="menu" opened>
    <div class="nav-wrap">
      <a routerLink="./" aria-label="Catering Admin Home" class="logo" >
        <img src="/assets/images/catering-logo.svg" alt="Catering Logo">
      </a>
      <mat-list>
        <mat-list-item>
          <a [routerLink]="['/admin/inquiry']">
            <mat-icon>mail</mat-icon> Inquiries
          </a>
        </mat-list-item>
        <mat-list-item>
          <a [routerLink]="['/admin/user']">
            <mat-icon>people</mat-icon> Users
          </a>
        </mat-list-item>
        <mat-list-item>
          <a [routerLink]="['/admin/menu/audience']">
            <mat-icon>food_bank</mat-icon> Menu
          </a>
        </mat-list-item>
        <mat-list-item  class="nested">
          <a [routerLink]="['/admin/menu/menu-item']">
            <mat-icon>lunch_dining</mat-icon>  Menu Items
          </a>
        </mat-list-item>
        <mat-list-item class="nested">
          <a [routerLink]="['/admin/menu/allergy']">
            <mat-icon>egg</mat-icon> Allergens
          </a>
        </mat-list-item>
        <mat-list-item  class="nested">
          <a [routerLink]="['/admin/menu/dietary-preference']">
            <mat-icon>no_meals</mat-icon> Dietary Preferences
          </a>
        </mat-list-item>
        <mat-list-item  class="nested">
          <a [routerLink]="['/admin/menu/category']">
            <mat-icon>label</mat-icon> Categories
          </a>
        </mat-list-item>
      </mat-list>
      <mat-list>
        <mat-list-item>
          <a href="https://docs.google.com/document/d/17-txFqnGNsMfm4sTZiaoHw4u3hdcuH1Ft5a_4beEXwg" target="blank">
            <mat-icon>description</mat-icon> Documentation
          </a>
        </mat-list-item>
        <mat-list-item>
          <a href="/admin/logout">
            <mat-icon>logout</mat-icon> Logout
          </a>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="pad">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

