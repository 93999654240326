<mat-card>
  <mat-card-title>Dietary Preferences</mat-card-title>
  <mat-card-subtitle>List of all dietary preferences</mat-card-subtitle>
  <mat-card-content>
    <admin-dietary-preference-list></admin-dietary-preference-list>
  </mat-card-content>
  <mat-card-actions align="end">
    <a mat-raised-button color="primary" [routerLink]="['/admin/menu/dietary-preference/create']">
      <mat-icon aria-hidden="false" aria-label="Edit icon">add_circle</mat-icon> Create Dietary Preference
    </a>
  </mat-card-actions>
</mat-card>
