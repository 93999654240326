import { Component, ViewChild, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InquiryService } from '@service/api/inquiry.service';
import { Inquiry } from '@interface/inquiry.interface';
import { Observable, Subscription, EMPTY } from 'rxjs';
import { tap, map, first, catchError, shareReplay } from 'rxjs/operators';
import { HydraCollection } from '@interface/hydra/collection.interface';
import { HydraConstraint } from '@interface/hydra/constraint.interface';
import { Router } from "@angular/router";

@Component({
  selector: 'admin-inquiry-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class AdminInquiryViewComponent {

  inquiry$!: Observable<any>;
  inquiry!: Inquiry;

  subscription$: Subscription[] = [];
  constructor(
    private route: ActivatedRoute,
    public api: InquiryService,
  ) {
    this.inquiry$ = this.route.data.pipe(
      map(data => {
        return data.data;
      })
    );
  }
  
  ngOnDestroy(): void {
    this.subscription$.forEach(element => {
      element.unsubscribe();
    });
  }
}
