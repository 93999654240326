import { Component, OnInit } from '@angular/core';
import { LoginService } from '@service/api/login.service';
import { environment } from '@environment/environment';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  protected environment = environment;

  url: string = '/Shibboleth.sso/Logout?return=' + encodeURIComponent(environment.siteUrl)

  constructor(
    private api: LoginService
  ) { 
    this.api.doLogout().subscribe((data:any) => {
      window.location.href = this.url;
    })
  }

  ngOnInit(): void {
    window.location.href = this.url;
  }

}
