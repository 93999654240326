<fieldset [formGroup]="form">
  <mat-form-field appearance="outline" class="full">
    <mat-label>Name</mat-label>
    <input matInput type="text" formControlName="name" id="name" required>
    <mat-error *ngIf="!isValid('name') && form.get('name')?.errors as errors">
      <div *ngIf="errors.required">This field is required</div>
      <div *ngIf="errors.minlength">This field must be at least {{errors.minlength.requiredLength}} characters</div>
      <div *ngIf="errors.maxlength">This field must not be longer than {{errors.maxlength.requiredLength}} characters</div>
      <div *ngIf="errors.server">{{errors.server}}</div>
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="full">
    <mat-label>Description</mat-label>
    <textarea matInput formControlName="description" id="description" required></textarea>
    <mat-error *ngIf="!isValid('description') && form.get('description')?.errors as errors">
      <div *ngIf="errors.required">This field is required</div>
      <div *ngIf="errors.minlength">This field must be at least {{errors.minlength.requiredLength}} characters</div>
      <div *ngIf="errors.maxlength">This field must not be longer than {{errors.maxlength.requiredLength}} characters</div>
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="full">
    <mat-label>Short Description</mat-label>
    <textarea matInput formControlName="shortDescription" id="shortDescription"></textarea>
    <mat-error *ngIf="!isValid('shortDescription') && form.get('shortDescription')?.errors as errors">
      <div *ngIf="errors.required">This field is required</div>
      <div *ngIf="errors.minlength">This field must be at least {{errors.minlength.requiredLength}} characters</div>
      <div *ngIf="errors.maxlength">This field must not be longer than {{errors.maxlength.requiredLength}} characters</div>
    </mat-error>
  </mat-form-field>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Allergens
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form-allergy-options formControlName="allergies"></form-allergy-options>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Categories
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form-category-options formControlName="categories"></form-category-options>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Dietary Preferences
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form-dietary-preference-options formControlName="dietaryPreferences"></form-dietary-preference-options>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Nutrition Info
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form-nutrition-info formControlName="nutritionInfo"></form-nutrition-info>
    </mat-expansion-panel>
  </mat-accordion>
</fieldset>
