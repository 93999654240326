import { Component, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MenuItemService } from '@service/api/menuitem.service';
import { MenuItem } from '@interface/menuitem.interface';
import { Observable, Subscription, EMPTY } from 'rxjs';
import { tap, map, first, catchError, shareReplay } from 'rxjs/operators';
import { HydraCollection } from '@interface/hydra/collection.interface';
import { FormMenuItemComponent } from '@shared/forms/menu-item/menu-item.component';
import { HydraConstraint } from '@interface/hydra/constraint.interface';
import { Router } from "@angular/router";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { LooseObject } from '@interface/looseobject.interface';
import { DeleteDialog } from '@shared/dialog/delete/delete.dialog';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'admin-menu-item-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class AdminMenuItemEditComponent {


  slug$ = this.route.snapshot.paramMap.get('id') || '';
  menuItem$!: Observable<any>;
  form: FormGroup = this.fb.group({
    'menuItem': []
  });
  menuItem!:MenuItem;
  @ViewChild(FormMenuItemComponent) formMenuItemComponent!: FormMenuItemComponent;
  subscription$: Subscription[] = [];
  dialog$: Subscription = new Subscription();
  constructor(
    private route: ActivatedRoute,
    private api: MenuItemService,
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar

  ) {
    this.menuItem$ = this.route.data.pipe(
      map(data => data.data),
      map((data:any) => {
        this.menuItem = data;

        return {
          name: data.name,
          description: data.description,
          shortDescription: data.shortDescription,
          allergies: data.allergies,
          categories: data.categories,
          dietaryPreferences: data.dietaryPreferences,
          nutritionInfo: data.nutritionInfo
        }
      }),
      tap((data:any) => {
        let tmp = this.form.get('menuItem') as FormGroup;
        tmp.patchValue(data);
      })
    );
  }

  submit(): void {
    if(this.form.valid) {
      let input = this.form.get('menuItem') as FormGroup;
      let values = input.value;
      values['@id'] = this.api.getEndpoint() + '/' + this.slug$;
      this._queryApi(values);
    }
  }


  _queryApi(values: MenuItem) {
    this.subscription$.push(this.api.update<MenuItem>(values).pipe(
      catchError((data:any) => {
        data = data.error;
        this._processConstraints(data);
        return EMPTY;
      })
    ).subscribe((data) => {
      this._snackBar.open("Menu Item Updated.", 'close');
    }));
  }

  //Process error
  _processConstraints(data:HydraConstraint) {
    if(data['@type'] === 'ConstraintViolationList') {
      let obj:LooseObject = {};
      let childForm = this.formMenuItemComponent.form
      if(childForm) {
        data.violations.forEach((element:any) => {
          let control = childForm.get(element.propertyPath) as FormControl;
          control.setErrors({
            server: element.message
          });
        });
        this.form.setErrors({error:true});
      }
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteDialog, {
      data: {
        title: 'Delete Auience',
        message: 'Warning, this action cannot be undone!'
      },
    });

    this.subscription$.push(dialogRef.afterClosed().subscribe(result => {
      if(result) {
       this.subscription$.push(this.api.delete(this.menuItem['@id'] as string).subscribe((response) => {
         this.router.navigate(['/admin/menu/menu-item/']);
       }))
      }
    }));

  }
  ngOnDestroy(): void {
    this.subscription$.forEach(element => {
      element.unsubscribe();
    });
  }
}
