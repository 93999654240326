import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {map, mergeMap, startWith, debounceTime} from 'rxjs/operators';
import { MenuItem } from '@interface/menuitem.interface';
import { MenuItemService } from '@service/api/menuitem.service';
import { HydraCollection } from '@interface/hydra/collection.interface';

@Component({
  selector: 'app-item-search',
  templateUrl: './item-search.component.html',
  styleUrls: ['./item-search.component.scss']
})
export class ItemSearchComponent implements OnInit {
  constructor(
    private menuItemApi: MenuItemService
  ) { }

  myControl = new FormControl('');
  options: MenuItem[] = [];
  filteredOptions: Observable<MenuItem[]> = new Observable;
  menuItems$:Subscription = new Subscription();

  @Output() item = new EventEmitter<MenuItem>();

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      debounceTime(300),
      startWith(''),
      mergeMap((value:any) => {
        if(!value) {
          return new Observable();
        }
        return this.menuItemApi.getAll({'pagination': true, 'name': value }).pipe(
          map((data:HydraCollection): MenuItem[] => {
            return data['hydra:member'];
        }))
      }),
      map((value:any) => {
        this.options = value;
        return value;
      }),
    );
  }

  select(dd:string) {
    this.item.emit(this.options.find(x => x['@id'] === dd));
    this.myControl.reset();
  }
}
