import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

/* forms */
//import { ReactiveFormsModule } from '@angular/forms';

/* Modules */
import { AppComponent } from './app.component';
import { AdminDashboardModule } from '@module/admin-dashboard/admin-dashboard.module';
//import { CateringMenuModule } from '@module/catering-menu/catering-menu.module';
import { SharedModule } from '@module/shared/shared.module';

import { Router, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AdminDashboardModule,
    //CateringMenuModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    viewportScroller.setOffset([0, 60]);
    router.events.pipe(filter((e:any) => e instanceof Scroll)).subscribe((e: Scroll) => {
      if (e.anchor) {
        setTimeout(() => {
          let anchor = (e.anchor) ? e.anchor : '';
          viewportScroller.scrollToAnchor(anchor);
        })
      } else if (e.position) {
        // backward navigation
        viewportScroller.scrollToPosition(e.position);
      } else {
        // forward navigation
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
}
