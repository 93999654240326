import { Injectable } from '@angular/core';
import { ApiService } from '@service/api/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { HydraCollection } from '@interface/hydra/collection.interface';
import {MealAudienceCategory} from '@interface/mealaudiencecategory.interface';

@Injectable({
  providedIn: 'root'
})
export class MealAudienceCategoryService extends ApiService {
  endpoint: string = '/meal-audience-categories';
  constructor(
    protected http: HttpClient
  ) {
    super(http);
    this.setEndpoint(this.endpoint);
  }
}
