import { Injectable } from '@angular/core';
import { ApiService } from '@service/api/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { HydraCollection } from '@interface/hydra/collection.interface';
import {Allergy} from '@interface/allergy.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService {
  endpoint: string = '/users';
  constructor(
    protected http: HttpClient
  ) {
    super(http);
    this.setEndpoint(this.endpoint);
  }
}
