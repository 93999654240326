<ng-container *ngIf="api$ | async as collection">
  <mat-list role="list">
    <ng-container *ngFor="let member of collection['hydra:member']">
      <mat-list-item role="listitem">
        {{member.name}}

        <a mat-fab color="primary" aria-label="Edit AudienceCategory" [routerLink]="['/admin/menu/audience-category/edit/', this.api.getId(member['@id'])]">
           <mat-icon aria-hidden="false" aria-label="Edit icon">edit</mat-icon>
        </a>
      </mat-list-item>
      <mat-divider></mat-divider>
    </ng-container>
  </mat-list>
  <mat-paginator
    [length]="collection['hydra:totalItems']"
    pageSize="30"
    (page)="handlePage($event)"
    [pageIndex]="getIndex()"
    aria-label="Select page"
    >
    </mat-paginator>
</ng-container>
