<div mat-dialog-content>
  <h1 *ngIf="data.title">{{ data.title }}</h1>
  <p *ngIf="data.message">{{ data.message }}</p>
  <mat-form-field appearance="fill">
    <mat-label>Type DELETE to Confirm</mat-label>
    <input matInput placeholder="DELETE" [formControl]="form" autocomplete="off" cdkFocusInitial/>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button  (click)="onNoClick()">Cancel</button>
  <button mat-button  [disabled]="!form.valid" [mat-dialog-close]="true">Delete</button>
</div>
