<mat-card>
  <mat-card-title>Create Allergy</mat-card-title>
  <mat-card-content>
    <form [formGroup]="form" (submit)=submit()>
      <form-allergy formControlName="allergy"></form-allergy>
      <mat-card-actions align="end">
        <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">
          <mat-icon aria-hidden="false" aria-label="Edit icon">add_circle</mat-icon> Create Allergy
        </button>
      </mat-card-actions>
    </form>
  </mat-card-content>
</mat-card>
