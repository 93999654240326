import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { MealSet } from '@interface/mealset.interface';
import { MealSetService } from '@service/api/mealset.service';
import { Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { EMPTY, Observable} from "rxjs";
import { catchError, map, first } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class MealSetEditResolver implements Resolve<MealSet> {
  constructor(private api: MealSetService, private router: Router, private location: Location) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MealSet> {
    return this.api.get<MealSet>(route.params.mealsetId).pipe(
      first(),
      catchError((data) => {
        this.router.navigate(["**"], {
          skipLocationChange: true}
        ).then(() => {
          this.location.replaceState(state.url);
        });
        return EMPTY;
      })
    );
  }
}
