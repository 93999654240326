import { Component, OnInit, ViewChild } from '@angular/core';
import { InquiryService } from '@service/api/inquiry.service';
import { Inquiry } from '@interface/inquiry.interface';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HydraCollection } from '@interface/hydra/collection.interface';
import { MatTable } from '@angular/material/table';


@Component({
  selector: 'admin-inquiry-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class AdminInquiryListComponent {
  api$:Observable<HydraCollection> = this.api.getAll();
  index: number = 0;
  @ViewChild(MatTable) table!: MatTable<Inquiry>;
  displayedColumns: string[] = ['id', 'date', 'name', 'email', 'phone', 'subject'];

  constructor(public api: InquiryService) { }

  handlePage(event:any) {
    this.index = event.pageIndex;
    this.api$ = this.api.getAll({
      page: this.getPage()
    });
  }

  getPage(): number {
    return this.index+1;
  }
  getIndex(): number {
    return this.index;
  }


}
