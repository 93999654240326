<ng-container *ngIf="api$ | async as collection">
  <table mat-table [dataSource]="collection['hydra:member']" >
    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef> Username </th>
      <td mat-cell *matCellDef="let element"><a [routerLink]="['edit', api.getId(element['@id'])]">{{element.username}}</a></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    [length]="collection['hydra:totalItems']"
    pageSize="30"
    (page)="handlePage($event)"
    [pageIndex]="getIndex()"
    aria-label="Select page"
  ></mat-paginator>
</ng-container>
