import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AudienceService } from '@service/api/audience.service';
import { MenuAudience } from '@interface/audience.interface';
import { Observable, Subscription, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HydraCollection } from '@interface/hydra/collection.interface';
import {MatTable} from '@angular/material/table';


@Component({
  selector: 'admin-audience-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class AdminAudienceListComponent implements OnDestroy {
  api$:Observable<any> = this.api.getAll().pipe(map((data:HydraCollection) => {
    this.audience = data['hydra:member'];
    return data['hydra:member']
  }));
  audience: MenuAudience[] = [];
  index: number = 0;
  displayedColumns: string[] = ['name', 'slug', 'published', 'order']
  subscription$: Subscription[] = [];


  @ViewChild(MatTable) table!: MatTable<MenuAudience>;

  constructor(private api: AudienceService) { }

  handlePage(event:any) {
    this.index = event.pageIndex;
    this.api$ = this.api.getAll({
      page: this.getPage()
    });
  }

  getPage(): number {
    return this.index+1;
  }
  getIndex(): number {
    return this.index;
  }

  isFirst(i:number): boolean {
    if(i>0)
      return false;
    return true;
  }
  isLast(i:number): boolean {
    if(++i>=this.audience.length)
      return true;
    return false;
  }


  moveUp(i:number): void  {
    if(i <= 0) {
      return;
    }
    let iold:number = i;
    let inew:number = --i;
    [this.audience[iold], this.audience[inew]] = [this.audience[inew], this.audience[iold]]

    this.audience[inew].displayOrder = inew;
    this.audience[iold].displayOrder = iold;

    this.updateDisplayOrder(inew);
    this.updateDisplayOrder(iold);
    this.table.renderRows();

  }

  togglePublish(a:number) {
    this.audience[a].published = (this.audience[a].published) ? true : false;
    this.subscription$.push(this.api.update({
      '@id': this.audience[a]['@id'],
      'published': this.audience[a].published
    }).subscribe());
  }
  updateDisplayOrder(a:number): void  {
    this.subscription$.push(this.api.update({
      '@id': this.audience[a]['@id'],
      'displayOrder': this.audience[a].displayOrder
    }).subscribe());
  }

  moveDown(i:number): void {
    if(i >= this.audience.length) {
      return;
    }
    let iold:number = i;
    let inew:number = ++i;
    [this.audience[iold], this.audience[inew]] = [this.audience[inew], this.audience[iold]]

    this.audience[inew].displayOrder = inew;
    this.audience[iold].displayOrder = iold;

    this.updateDisplayOrder(inew);
    this.updateDisplayOrder(iold);
    this.table.renderRows();

  }

  ngOnDestroy() {
    this.subscription$.forEach(element => {
      element.unsubscribe();
    });
  }

}
