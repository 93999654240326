import { Component, ViewChild, OnDestroy, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MealAudienceCategoryService } from '@service/api/mealaudiencecategory.service';
import { MealAudienceCategory } from '@interface/mealaudiencecategory.interface';
import { LooseObject } from '@interface/looseobject.interface';
import { catchError } from 'rxjs/operators';
import { HydraCollection } from '@interface/hydra/collection.interface';
import { EMPTY, Observable, Subscription} from "rxjs";
import { FormAudienceCategoryComponent } from '@shared/forms/audience-category/audience-category.component';
import { HydraConstraint } from '@interface/hydra/constraint.interface';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'admin-audience-category-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class AudienceCategoryCreateDialogComponent implements OnDestroy {

  @ViewChild(FormAudienceCategoryComponent) formAudienceCategoryComponent!: FormAudienceCategoryComponent;
  form: FormGroup = this.fb.group({
    'audienceCategory': []
  });
  subscription$: Subscription = new Subscription();
  constructor(
    private api: MealAudienceCategoryService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AudienceCategoryCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LooseObject
  ) {
    let input = this.form.get('audienceCategory') as FormGroup;
    input.patchValue({
      menuAudience: data.audience['@id']
    })

  }

  submit(): void {
    if(this.form.valid) {
      let input = this.form.get('audienceCategory') as FormGroup;
      let values = input.value;
      this._queryApi(values);
    }
  }

  _queryApi(values: MealAudienceCategory) {
  this.subscription$ = this.api.create<MealAudienceCategory>(values).pipe(
      catchError((data:any) => {
        data = data.error;
        this._processConstraints(data);
        return EMPTY;
      })
    ).subscribe((data:MealAudienceCategory) => {
      this.dialogRef.close({
        data: data
      });
    });
  }

  //Process error
  _processConstraints(data:HydraConstraint) {
    if(data['@type'] === 'ConstraintViolationList') {
      let obj:LooseObject = {};
      let childForm = this.formAudienceCategoryComponent.form
      if(childForm) {
        data.violations.forEach((element:any) => {
          let control = childForm.get(element.propertyPath) as FormControl;
          control.setErrors({
              server: element.message
          });
        });
        this.form.setErrors({error:true});

      }
    }
  }
  ngOnDestroy():void {
    this.subscription$.unsubscribe();
  }
}
