<mat-card>
  <mat-card-title>Event Types</mat-card-title>
  <mat-card-subtitle>Organize your event types</mat-card-subtitle>
  <mat-card-content>
    <admin-audience-list></admin-audience-list>
  </mat-card-content>
  <mat-card-actions align="end">
    <a mat-raised-button color="primary" [routerLink]="['/admin/menu/audience/create']">
      <mat-icon aria-hidden="false" aria-label="Edit icon">add_circle</mat-icon>Create event type
    </a>
  </mat-card-actions>
</mat-card>
