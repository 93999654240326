import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule } from '@angular/forms';

/* Directives */
import { DigitOnlyDirective } from '@shared/directives/digitonly/digit-only.directive';

/* Control value accessors */
import { FormAudienceComponent } from '@shared/forms/audience/audience.component';
import { FormAudienceCategoryComponent } from '@shared/forms/audience-category/audience-category.component';
import { FormAllergyComponent } from '@shared/forms/allergy/allergy.component';
import { FormUserComponent } from '@shared/forms/user/user.component';

import { FormCategoryComponent } from '@shared/forms/category/category.component';
import { FormDietaryPreferenceComponent } from '@shared/forms/dietary-preference/dietary-preference.component';
import { FormMenuItemComponent } from '@shared/forms/menu-item/menu-item.component';
import { FormAllergyOptionsComponent } from '@shared/forms/allergy-options/allergy.component';
import { FormCategoryOptionsComponent } from '@shared/forms/category-options/category.component';
import { FormDietaryPreferenceOptionsComponent } from '@shared/forms/dietary-preference-options/dietary-preference.component';
import { FormNutritionInfoComponent } from '@shared/forms/nutrition-info/nutrition.component';
import { FormMealSetComponent } from '@shared/forms/meal-set/meal-set.component';
import { FormMenuItemOptionsComponent } from '@shared/forms/menu-item-options/menu-item-options.component';
import { FormMealAudienceCategoryOptionsComponent } from '@shared/forms/audience-category-options/audience-category-options.component';


/* Material Imports */
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSelectModule} from '@angular/material/select';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTableModule} from '@angular/material/table';
import {MatTreeModule} from '@angular/material/tree';
import {MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';




/* Dialogs */
import { DeleteDialog } from '@shared/dialog/delete/delete.dialog';
import { AudienceCategoryCreateDialogComponent } from '@shared/dialog/create-audience-category-dialog/create.component';


import { ItemSearchComponent } from './components/item-search/item-search.component';



@NgModule({
  providers: [
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 3000}}
  ],
  declarations: [
    DigitOnlyDirective,
    FormAudienceComponent,
    FormAudienceCategoryComponent,
    FormAllergyComponent,
    FormCategoryComponent,
    FormDietaryPreferenceComponent,
    FormMenuItemComponent,
    FormAllergyOptionsComponent,
    FormCategoryOptionsComponent,
    FormDietaryPreferenceOptionsComponent,
    FormNutritionInfoComponent,
    FormMealSetComponent,
    FormMenuItemOptionsComponent,
    FormMealAudienceCategoryOptionsComponent,
    FormUserComponent,
    DeleteDialog,
    AudienceCategoryCreateDialogComponent,
    ItemSearchComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatDividerModule,
    MatListModule,
    MatCardModule,
    MatPaginatorModule,
    MatIconModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatExpansionModule,
    MatSelectModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatTableModule,
    MatTreeModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatTreeModule,
    MatTooltipModule
  ],
  exports: [
    CommonModule,
    DeleteDialog,
    AudienceCategoryCreateDialogComponent,
    DigitOnlyDirective,
    ItemSearchComponent,
    FormAudienceComponent,
    FormAudienceCategoryComponent,
    FormAllergyComponent,
    FormCategoryComponent,
    FormDietaryPreferenceComponent,
    FormMenuItemComponent,
    FormAllergyOptionsComponent,
    FormCategoryOptionsComponent,
    FormDietaryPreferenceOptionsComponent,
    FormNutritionInfoComponent,
    FormMealSetComponent,
    FormMenuItemOptionsComponent,
    FormMealAudienceCategoryOptionsComponent,
    FormUserComponent,
    ReactiveFormsModule,
    MatSidenavModule,
    MatDividerModule,
    MatListModule,
    MatCardModule,
    MatPaginatorModule,
    MatIconModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatExpansionModule,
    MatSelectModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatTableModule,
    MatTreeModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatTreeModule,
    MatTooltipModule
  ]
})
export class SharedModule { }
