import { Component, OnInit, OnDestroy, Input, forwardRef, ChangeDetectorRef } from '@angular/core';
import { NgControl, FormControl, FormBuilder, FormGroup, FormArray, Validator, Validators, ControlValueAccessor, AbstractControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { HydraCollection } from '@interface/hydra/collection.interface';
import { DietaryPreference } from '@interface/dietarypreference.interface';
import { DietaryPreferenceService } from '@service/api/dietary-preference.service';


@Component({
  selector: 'form-dietary-preference-options',
  templateUrl: './dietary-preference.component.html',
  styleUrls: ['./dietary-preference.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormDietaryPreferenceOptionsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormDietaryPreferenceOptionsComponent),
      multi: true
    }
  ]
})
export class FormDietaryPreferenceOptionsComponent implements ControlValueAccessor, OnDestroy, Validator {


  constructor(
    private fb: FormBuilder,
    private dpApi: DietaryPreferenceService,
  ) {
    this.onChangeSubs.push(this.form.valueChanges.subscribe((value) => {
      this.onChanged(this.getValues(value.dietaryPreferences));
      this.onTouched();
    }))
  }

  form:FormGroup = this.fb.group({
    'dietaryPreferences': this.fb.array([])
  })

  selected: String[] = [];
  dietaryPreferences:DietaryPreference[] = [];
  dietaryPreferences$:Observable<DietaryPreference[]> = this.dpApi.getAll({'pagination': false}).pipe(
    map((data:HydraCollection): DietaryPreference[] => {
      return data['hydra:member'];
    }),
    tap((data:DietaryPreference[]) => {
      this.dietaryPreferences = data;
      data.forEach((element, index) => {

        let ctrl = this.getControl('dietaryPreferences');
        ctrl.push(new FormControl(this.selected.includes(element['@id'] as string)));
      });
    })
  );

  onTouched: Function = () => {};
  onChanged: Function = () => {};

  onChangeSubs: Subscription[] = [];

  getValues(values:Boolean[]) {
    let arr: String[] = [];
    values.forEach((element, index) => {
      if(element)
        arr.push(this.dietaryPreferences[index]['@id'] as string)
    });

    return arr;
  }
  ngOnDestroy(): void {
    for (let sub of this.onChangeSubs) {
      sub.unsubscribe();
    }
  }

  getControl(control: string): FormArray {
    return this.form.get(control) as FormArray;
  }
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.form.disable();
    }
    else {
      this.form.enable();
    }
  }

  writeValue(value: any): void {
    if (value) {
      this.selected = value;
    }
  }

  validate(control: AbstractControl) {
    return this.form.valid ? null : { valid: false, message: 'Invalid dietarypreference' };
  }

}
