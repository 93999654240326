import { Component, OnInit } from '@angular/core';
import { NgControl, FormControl, FormBuilder, FormGroup, FormArray, Validator, Validators, ControlValueAccessor, AbstractControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { LoginService } from '@service/api/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  constructor(
    private fb: FormBuilder,
    private login: LoginService,
    private router: Router,
  ) {}

  form: FormGroup = this.fb.group({
    username: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(45)
    ]),
    password: new FormControl('', [
      Validators.required
    ])
  });

  ngOnInit(): void {
  }

  error:boolean = false;

  submit() {
    let user = this.form.get('username') as FormControl;
    let password = this.form.get('password') as FormControl

    this.login.doLogin(user.value, password.value).subscribe(
      (response:any) => {
        this.router.navigate(['/admin'])
      },
      (error:any) => {
        if(error.status == 401)
          this.error = true;
    });

  }

}
