<form [formGroup]="form" (submit)=submit()>  
  <div mat-dialog-content>
    <form-audience-category formControlName="audienceCategory"></form-audience-category>
  </div>  
  <div mat-dialog-actions align="end">
    <button mat-button color="primary" type="submit" [disabled]="!form.valid">
      <mat-icon aria-hidden="false" aria-label="Edit icon">add_circle</mat-icon> Create AudienceCategory
    </button>
  </div>
</form>
