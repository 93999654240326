import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/* admin includes */
import { SharedModule } from '@module/shared/shared.module';
import { AdminDashboardRoutingModule } from './admin-dashboard-routing.module';
import { AdminDashboardComponent } from './admin-dashboard.component';
import { AdminMenuComponent } from '@admin/component/menu/menu.component';

/* Audience */
import { AdminAudienceListComponent } from '@admin/component/audience/list/list.component';
import { AdminAudienceEditComponent } from '@admin/component/audience/edit/edit.component';
import { AdminAudienceCreateComponent } from '@admin/component/audience/create/create.component';
import { AdminAudienceIndexComponent } from '@admin/component/audience/index/index.component';

/* Audience Category */
import { AdminAudienceCategoryCreateComponent } from '@admin/component/audience-category/create/create.component';
import { AdminAudienceCategoryListComponent } from '@admin/component/audience-category/list/list.component';
import { AdminAudienceCategoryIndexComponent } from '@admin/component/audience-category/index/index.component';
import { AdminAudienceCategoryEditComponent } from '@admin/component/audience-category/edit/edit.component';

/* Meal Set */
import { AdminMealSetCreateComponent } from '@admin/component/meal-set/create/create.component';
import { AdminMealSetListComponent } from '@admin/component/meal-set/list/list.component';
import { AdminMealSetIndexComponent } from '@admin/component/meal-set/index/index.component';
import { AdminMealSetEditComponent } from '@admin/component/meal-set/edit/edit.component';

/* Allergy */
import { AdminAllergyCreateComponent } from '@admin/component/allergy/create/create.component';
import { AdminAllergyEditComponent } from '@admin/component/allergy/edit/edit.component';
import { AdminAllergyListComponent } from '@admin/component/allergy/list/list.component';
import { AdminAllergyIndexComponent } from '@admin/component/allergy/index/index.component';

/* Category */
import { AdminCategoryCreateComponent } from '@admin/component/category/create/create.component';
import { AdminCategoryEditComponent } from '@admin/component/category/edit/edit.component';
import { AdminCategoryListComponent } from '@admin/component/category/list/list.component';
import { AdminCategoryIndexComponent } from '@admin/component/category/index/index.component';

/* Dietary Preference */
import { AdminDietaryPreferenceListComponent } from '@admin/component/dietary-preference/list/list.component';
import { AdminDietaryPreferenceCreateComponent } from '@admin/component/dietary-preference/create/create.component';
import { AdminDietaryPreferenceEditComponent } from '@admin/component/dietary-preference/edit/edit.component';
import { AdminDietaryPreferenceIndexComponent } from '@admin/component/dietary-preference/index/index.component';

/* Menu Item */
import { AdminMenuItemListComponent } from '@admin/component/menu-item/list/list.component';
import { AdminMenuItemIndexComponent } from '@admin/component/menu-item/index/index.component';
import { AdminMenuItemEditComponent } from '@admin/component/menu-item/edit/edit.component';
import { AdminMenuItemCreateComponent } from '@admin/component/menu-item/create/create.component';
import { LoginComponent } from './components/login/login.component';

import { AuthGuardService } from './guard/auth.service';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';

/* Allergy */
import { AdminUserCreateComponent } from '@admin/component/user/create/create.component';
import { AdminUserEditComponent } from '@admin/component/user/edit/edit.component';
import { AdminUserListComponent } from '@admin/component/user/list/list.component';
import { AdminUserIndexComponent } from '@admin/component/user/index/index.component';

import { AdminInquiryIndexComponent } from '@admin/component/inquiry/index/index.component';
import { AdminInquiryViewComponent } from '@admin/component/inquiry/view/view.component';
import { AdminInquiryListComponent } from '@admin/component/inquiry/list/list.component';
import { LogoutComponent } from './components/logout/logout.component';

@NgModule({
  declarations: [
    AdminAudienceListComponent,
    AdminDashboardComponent,
    AdminMenuComponent,
    AdminAudienceEditComponent,
    AdminAudienceCreateComponent,
    AdminAudienceIndexComponent,
    AdminAllergyCreateComponent,
    AdminAllergyEditComponent,
    AdminAllergyListComponent,
    AdminAllergyIndexComponent,
    AdminCategoryCreateComponent,
    AdminCategoryEditComponent,
    AdminCategoryListComponent,
    AdminCategoryIndexComponent,
    AdminDietaryPreferenceListComponent,
    AdminDietaryPreferenceEditComponent,
    AdminDietaryPreferenceCreateComponent,
    AdminDietaryPreferenceIndexComponent,
    AdminMenuItemListComponent,
    AdminMenuItemIndexComponent,
    AdminMenuItemEditComponent,
    AdminMenuItemCreateComponent,
    AdminAudienceCategoryCreateComponent,
    AdminAudienceCategoryListComponent,
    AdminAudienceCategoryIndexComponent,
    AdminAudienceCategoryEditComponent,
    AdminMealSetCreateComponent,
    AdminMealSetListComponent,
    AdminMealSetIndexComponent,
    AdminMealSetEditComponent,
    AdminUserCreateComponent,
    AdminUserEditComponent,
    AdminUserListComponent,
    AdminUserIndexComponent,
    AdminInquiryViewComponent,
    AdminInquiryIndexComponent,
    AdminInquiryListComponent,
    LoginComponent,
    BreadcrumbComponent,
    LogoutComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AdminDashboardRoutingModule
  ],
  providers: [AuthGuardService]
})
export class AdminDashboardModule { }
