<mat-card *ngIf="allergy$ | async">
  <mat-card-title>Update Allergen</mat-card-title>
  <mat-card-content>
    <form [formGroup]="form" (submit)=submit()>
      <form-allergy formControlName="allergy"></form-allergy>
      <mat-card-actions align="end">
        <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid"><mat-icon aria-hidden="false" aria-label="Edit icon">save</mat-icon> Update</button>
        <button mat-raised-button color="warn" (click)="openDialog()" type="button"><mat-icon aria-hidden="false" aria-label="Edit icon">delete</mat-icon> Delete</button>
      </mat-card-actions>
    </form>
  </mat-card-content>
</mat-card>
