import { Component, OnInit, OnDestroy, Input, forwardRef, ChangeDetectorRef } from '@angular/core';
import { NgControl, FormControl, FormBuilder, FormGroup, FormArray, Validator, Validators, ControlValueAccessor, AbstractControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'form-allergy',
  templateUrl: './allergy.component.html',
  styleUrls: ['./allergy.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormAllergyComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormAllergyComponent),
      multi: true
    }
  ]
})
export class FormAllergyComponent implements ControlValueAccessor, OnDestroy, Validator {


  constructor(
    private fb: FormBuilder,
  ) {}

  form: FormGroup = this.fb.group({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(45)
    ]),
  });

  onTouched: Function = () => {};
  onChanged: Function = () => {};

  onChangeSubs: Subscription[] = [];


  ngOnDestroy(): void {
    for (let sub of this.onChangeSubs) {
      sub.unsubscribe();
    }
  }

  registerOnChange(fn: any): void {
     this.onChangeSubs.push(this.form.valueChanges.subscribe(fn));
   }
   registerOnTouched(fn: any): void {
     this.onTouched = fn;
   }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.form.disable();
    }
    else {
      this.form.enable();
    }
  }

  writeValue(value: any): void {
    if (value) {
      this.form.setValue(value, {emitEvent: false});
    }
  }

  validate(control: AbstractControl) {
    return this.form.valid ? null : { valid: false, message: 'Invalid allergy' };
  }

  isValid(name: string): boolean {

    if(this.form.get(name)) {
      let control = this.form.get(name) as FormControl;
      return control.valid;
    }

    return false;
  }
}
