import { Component, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from '@service/api/user.service';
import { User } from '@interface/user.interface';
import { LooseObject } from '@interface/looseobject.interface';
import { catchError } from 'rxjs/operators';
import { HydraCollection } from '@interface/hydra/collection.interface';
import { EMPTY, Observable, Subscription} from "rxjs";
import { FormUserComponent } from '@shared/forms/user/user.component';
import { HydraConstraint } from '@interface/hydra/constraint.interface';
import { Router } from "@angular/router";


@Component({
  selector: 'admin-user-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class AdminUserCreateComponent implements OnDestroy {

  @ViewChild(FormUserComponent) formUserComponent!: FormUserComponent;
  form: FormGroup = this.fb.group({
    'user': []
  });
  subscription$: Subscription = new Subscription();
  constructor(
    private api: UserService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  submit(): void {
    if(this.form.valid) {
      let input = this.form.get('user') as FormGroup;
      let values = input.value;
      this._queryApi(values);
    }
  }

  _queryApi(values: User) {
  this.subscription$ = this.api.create<User>(values).pipe(
      catchError((data:any) => {
        data = data.error;
        this._processConstraints(data);
        return EMPTY;
      })
    ).subscribe((data:User) => {
      this.router.navigate(['/admin/user/']);
    });
  }

  //Process error
  _processConstraints(data:HydraConstraint) {
    if(data['@type'] === 'ConstraintViolationList') {
      let obj:LooseObject = {};
      let childForm = this.formUserComponent.form
      if(childForm) {
        data.violations.forEach((element:any) => {
          let control = childForm.get(element.propertyPath) as FormControl;
          control.setErrors({
              server: element.message
          });
        });
        this.form.setErrors({error:true});

      }
    }
  }
  ngOnDestroy():void {
    this.subscription$.unsubscribe();
  }
}
