import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '@environment/environment';
import { catchError, map, tap, shareReplay } from 'rxjs/operators';
import { HydraCollection } from '@interface/hydra/collection.interface';
import { HydraMember } from '@interface/hydra/member.interface';
import { LooseObject } from '@interface/looseobject.interface';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  protected environment = environment;
  protected endpoint: string = '';
  protected defaultHeaders = {
    'Content-Type': 'application/ld+json'
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/ld+json',
    })
  }
  constructor(
    protected http: HttpClient
  ) { }

  protected handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log('Error');
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return throwError(error);
      //return of(result as T);
    };
  }

  protected setEndpoint(endpoint:string): void {
    this.endpoint = endpoint;
  }
  public getEndpoint(): string {
    return this.endpoint;
  }
  protected log(message: string):void {
    console.log(message)
  }
  protected getApiUrl(endpoint:string = ''): string {
    const regex = new RegExp(this.endpoint, 'gm');
    let url = this.getEndpoint() + '/' + this.getId(endpoint.replace(regex, ''));
    url = this.environment.apiUrl +  url;
    return url.replace(/\/$/g, '');
  }

  public getId(string:any): number|string {
    return string.replace(/.*\//gm, ''); //Nuke everything but the last ID
  }

  getAll(params: LooseObject = {}): Observable<HydraCollection> {
    return this.http.get<HydraCollection>(
      this.getApiUrl(this.endpoint), {params: new HttpParams().appendAll(params)}
    ).pipe(
      catchError(this.handleError<any>('getAll', []))
    );
  }
  get<T>(id:string): Observable<T> {
    return this.http.get<T>(this.getApiUrl(id)).pipe(
      shareReplay(),
      catchError(this.handleError<any>('get', []))
    );
  }
  create<T>(obj:T): Observable<T> {
    console.log('api-service')
    return this.http.post<T>(this.getApiUrl(), obj, this.httpOptions).pipe(
      catchError(this.handleError<any>('create', []))
    );
  }
  update<T extends HydraMember>(obj:T): Observable<T> {
    if(obj['@id']) {
      return this.http.put<T>(this.getApiUrl(obj['@id']), obj, this.httpOptions).pipe(
        catchError(this.handleError<any>('update', []))
      );
    }
    return of(obj as T);
  }
  delete<T>(id:string): Observable<T> {
    return this.http.delete<T>(this.getApiUrl(id)).pipe(
      catchError(this.handleError<any>('delete', []))
    );
  }
}
