<mat-form-field class="full-width" appearance="fill">
    <mat-label>Menu Item</mat-label>
    <input type="text"
           placeholder="Pick one"
           aria-label="Number"
           matInput
           [formControl]="myControl"
           [matAutocomplete]="auto">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="select($event.option.value)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option['@id']">
        {{option.name}} | {{option.shortDescription}}
      </mat-option>
    </mat-autocomplete>
    <mat-icon matSuffix>search</mat-icon>
</mat-form-field>
