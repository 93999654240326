<mat-card>
  <mat-card-title>Allergens</mat-card-title>
  <mat-card-subtitle>List of all allergens</mat-card-subtitle>
  <mat-card-content>
    <admin-allergy-list></admin-allergy-list>
  </mat-card-content>
  <mat-card-actions align="end">
    <a mat-raised-button color="primary" [routerLink]="['/admin/menu/allergy/create']">
      <mat-icon aria-hidden="false" aria-label="Edit icon">add_circle</mat-icon>Create Allergen
    </a>
  </mat-card-actions>
</mat-card>
