<ng-container *ngIf="dietaryPreferences$ | async as obj">
  <ng-container [formGroup]="form">
    <ng-container formArrayName="dietaryPreferences">
      <mat-list>
        <ng-container *ngFor="let dietaryPreference of obj; index as i">
          <mat-list-item>
            <mat-slide-toggle  [formControlName]="i" > {{ dietaryPreference.name }} </mat-slide-toggle>
          </mat-list-item>
        </ng-container>
      </mat-list>
    </ng-container>
  </ng-container>
</ng-container>
