<ng-container *ngIf="categories$ | async as obj">
    <ng-container [formGroup]="form">
      <ng-container formArrayName="categories">
        <mat-list>
          <ng-container *ngFor="let category of obj; index as i">
            <mat-list-item>
              <mat-slide-toggle [formControlName]="i">{{ category.name }}</mat-slide-toggle>
            </mat-list-item>
          </ng-container>
        </mat-list>
      </ng-container>
    </ng-container>
</ng-container>
