<fieldset [formGroup]="form">
  <mat-form-field appearance="outline" class="full">
    <mat-label>Name</mat-label>
    <input matInput type="text" formControlName="name" id="name" required>
    <mat-error *ngIf="!isValid('name') && form.get('name')?.errors as errors">
      <div *ngIf="errors.required">This field is required</div>
      <div *ngIf="errors.minlength">This field must be at least {{errors.minlength.requiredLength}} characters</div>
      <div *ngIf="errors.maxlength">This field must not be longer than {{errors.maxlength.requiredLength}} characters</div>
      <div *ngIf="errors.server">{{errors.server}}</div>
    </mat-error>    
  </mat-form-field>
  <mat-form-field appearance="outline" class="full">
    <mat-label>Description</mat-label>
    <input matInput type="text" formControlName="description" id="description" required>
    <mat-error *ngIf="!isValid('description') && form.get('description')?.errors as errors">
      <div *ngIf="errors.required">This field is required</div>
      <div *ngIf="errors.minlength">This field must be at least {{errors.minlength.requiredLength}} characters</div>
      <div *ngIf="errors.maxlength">This field must not be longer than {{errors.maxlength.requiredLength}} characters</div>
      <div *ngIf="errors.server">{{errors.server}}</div>
    </mat-error>
  </mat-form-field>

  <br/>
    <div class="image-select">
      <div class="image-path" *ngIf="image_path">
        <img [attr.src]="image_path" />
      </div>
      <label *ngIf="image_path" for="image" class="replace-image">Replace Image</label>
      <label *ngIf="!image_path" for="image" class="add-image">Add Image</label>
  
      <input type="file" accept="image/jpeg, image/png" #image id="image" required (change)="change($event)" (blur)="input_touched = true">
      <mat-error *ngIf="input_touched">
        <div *ngIf="file_errors.required">Image is required</div>
        <div *ngIf="file_errors.image_large">Image must not exceed {{image_max_size/1000}}kB</div>
        <div *ngIf="file_errors.image_dims">Image must be {{image_width}}x{{image_height}}px</div>
      </mat-error>
      
    </div>
    <mat-hint>Image must not exceed {{image_max_size/1000}}kB. </mat-hint>
    <mat-hint>Image must be {{image_width}}x{{image_height}}px</mat-hint>  <br/>
    <br/>
</fieldset>
