<mat-card class="includes" *ngIf="menuItems$ | async">
  <mat-card-title>Included Items</mat-card-title>
  <mat-card-subtitle>Select Menu Items that are Included in this Meal Set</mat-card-subtitle>
  <mat-card-content>
    <app-item-search (item)="addInclude($event)"></app-item-search>
    <table mat-table [dataSource]="getIncludesArray()" >
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
      </ng-container>
      <ng-container matColumnDef="shortDescription">
        <th mat-header-cell *matHeaderCellDef> Short Description </th>
        <td mat-cell *matCellDef="let element">{{element.shortDescription}}</td>
      </ng-container>
      <ng-container matColumnDef="displayOrder">
        <th mat-header-cell *matHeaderCellDef> Order </th>
        <td mat-cell *matCellDef="let element; let i=index">
          <button mat-mini-fab color="primary" type="button" [disabled]="i==0" (click)="moveUp(includes, i)"><mat-icon>arrow_upward</mat-icon></button>
          <button mat-mini-fab color="primary" type="button" [disabled]="i==(includes.controls.length-1)" (click)="moveDown(includes, i)"><mat-icon>arrow_downward</mat-icon></button>
        </td>
      </ng-container>
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef> Delete </th>
        <td mat-cell *matCellDef="let element; let i=index">
          <button type="button" mat-mini-fab color="warn" (click)="removeInclude(i)"><mat-icon>delete</mat-icon></button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </mat-card-content>
</mat-card>
