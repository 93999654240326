import { Component, Inject } from '@angular/core';
import { LooseObject } from '@interface/looseobject.interface';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'delete-dialog',
  templateUrl: './dialog-delete.template.html',
  styleUrls: ['./dialog-delete.scss']

})
export class DeleteDialog {
  form: FormControl = new FormControl('', [Validators.required, Validators.pattern('^DELETE$')])
  constructor(
    public dialogRef: MatDialogRef<DeleteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: LooseObject,
    private fb: FormBuilder,
  ) {}

    onNoClick(): void {
      this.dialogRef.close();
    }
}
