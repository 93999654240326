import { Component, OnInit, OnDestroy, Input, forwardRef, ChangeDetectorRef, ViewChild } from '@angular/core';
import { NgControl, FormControl, FormBuilder, FormGroup, FormArray, Validator, Validators, ControlValueAccessor, AbstractControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { tap, map, first, startWith } from 'rxjs/operators';

import { HydraCollection } from '@interface/hydra/collection.interface';
import { MenuItem } from '@interface/menuitem.interface';
import { MenuItemService } from '@service/api/menuitem.service';
import { MatTable } from '@angular/material/table';


@Component({
  selector: 'form-menu-item-options',
  templateUrl: './menu-item-options.component.html',
  styleUrls: ['./menu-item-options.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormMenuItemOptionsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormMenuItemOptionsComponent),
      multi: true
    }
  ]
})
export class FormMenuItemOptionsComponent implements ControlValueAccessor, OnInit, OnDestroy, Validator {

  menuItems:MenuItem[] = [];
  menuItems$:Observable<MenuItem[]> = this.menuItemApi.getAll({'pagination': false}).pipe(
  first(),
  map((data:HydraCollection): MenuItem[] => {
    return data['hydra:member'];
  }),
  map((data:MenuItem[]) => {
    this.menuItems = data;
    return data;
  }));

  form:FormGroup = this.fb.group({
    includes: new FormArray([])
  })

  inputCtrl:FormControl =  new FormControl('');
  @ViewChild(MatTable) table!: MatTable<MenuItem>;
  displayedColumns: string[] = ['name', 'shortDescription', 'displayOrder', 'delete'];


  constructor(
    private fb: FormBuilder,
    private menuItemApi: MenuItemService,
  ) {
    this.onChangeSubs.push(this.form.valueChanges.subscribe((value) => {

      let arr:Object[] = [];
      value.includes.forEach((element:any) => {
        arr.push(element)
      });
      this.onChanged(arr);
      this.onTouched();
    }))

  }

  ngOnInit() {

  }

  private _filterItems(value: any): MenuItem[] {
    const filterValue = value.toLowerCase();
    return this._filterSelected(this.menuItems.filter((item:any) => item.name.toLowerCase().includes(filterValue)));
  }

  private _filterSelected(value:any): MenuItem[] {
    let selected = (this.form.get('includes') as FormArray).controls.map((value:any) => {
      return value.controls.menuItem.value
    });
    return value.filter((x:MenuItem) => {
      return (!selected.includes(x['@id']))
    });
  }

  get includes():FormArray {
    return this.form.get('includes') as FormArray;
  }

  removeInclude(index:number): void {
    this.includes.removeAt(index)
  }
  returnIncludeForm(): FormGroup {
    return this.fb.group({
      displayOrder: [0, [
        Validators.required
      ]],
      menuItem: ['', [Validators.required]]
    });
  }

  onTouched: Function = () => {};
  onChanged: Function = () => {};
  onChangeSubs: Subscription[] = [];

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.form.disable();
      this.inputCtrl.disable();
    } else {
      this.form.enable();
      this.inputCtrl.enable();
    }
  }

  moveUp(arr:FormArray, i:number) {
    if(i <= 0) {
      return;
    }

    let iold:number = i;
    let inew:number = --i;

    let tmp = arr.controls[iold]
    arr.controls[iold] = arr.controls[inew];
    arr.controls[inew] = tmp;

    (arr.controls[iold] as FormGroup).controls['displayOrder'].setValue(iold);
    (arr.controls[inew] as FormGroup).controls['displayOrder'].setValue(inew);
  }

  moveDown(arr:FormArray, i:number) {
    if(i >= arr.length) {
      return;
    }

    let iold:number = i;
    let inew:number = ++i;

    let tmp = arr.controls[iold]
    arr.controls[iold] = arr.controls[inew];
    arr.controls[inew] = tmp;

    (arr.controls[iold] as FormGroup).controls['displayOrder'].setValue(iold);
    (arr.controls[inew] as FormGroup).controls['displayOrder'].setValue(inew);

  }

  writeValue(value: any): void {
    if (value) {
      value.forEach((element:any) => {
        const ctrl = this.returnIncludeForm() as FormGroup;
        const form = this.form.get('includes') as FormArray;
        ctrl.addControl('@id', new FormControl(element['@id']));
        (ctrl.get('menuItem') as FormControl).setValue(element.menuItem);
        (ctrl.get('displayOrder') as FormControl).setValue(element.displayOrder);
        form.push(ctrl);
      });
    }
  }

  displayItems(id:number): MenuItem | undefined {
    let includes = (this.form.get('includes') as FormArray).controls[id];
    let item = this.findItemById((includes.get('menuItem') as FormControl).value);
    return item;
  }

  findItemById(id:string) {
    return this.menuItems.find((item:MenuItem) => { return item['@id'] === id})
  }

  validate(control: AbstractControl) {
    return this.form.valid ? null : { message: 'Error with includes' };
  }

  addInclude(dd:any) {
    let ctrl = this.returnIncludeForm() as FormGroup;
    (ctrl.get('menuItem') as FormControl).setValue(dd['@id']);
    this.includes.push(ctrl);
    this.inputCtrl.reset();
  }

  ngOnDestroy(): void {
    for (let sub of this.onChangeSubs) {
      sub.unsubscribe();
    }
  }

  getIncludesArray():MenuItem[] {
    const includes = this.form.get('includes') as FormArray;
    const includedArr:MenuItem[] = [];

    includes.value.forEach((element:any) => {
      const item = this.findItemById(element.menuItem);
      if(item)
        includedArr.push(item);
    });

    return includedArr;
  }
}
