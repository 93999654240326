<app-breadcrumb></app-breadcrumb>
<ng-container *ngIf="audienceCategory$ | async">
  <mat-card>
    <mat-card-title>Edit Audience Category</mat-card-title>
    <mat-card-content>
      <form [formGroup]="form" (submit)=submit()>
        <form-audience-category formControlName="audienceCategory"></form-audience-category>
        <mat-card-actions align="end">
          <button type="submit" mat-raised-button color="primary" [disabled]="!form.valid"><mat-icon aria-hidden="false">save</mat-icon> Update Category</button>
          <button type="button" mat-raised-button color="warn" (click)="openDialog()"><mat-icon aria-hidden="false">delete</mat-icon>Delete Category</button>
        </mat-card-actions>
      </form>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Meal Sets</mat-card-title>
    <mat-card-subtitle>Categories are made up of a collection of meal sets</mat-card-subtitle>
    <mat-card-content>
        <table *ngIf="audienceCategory.mealSets" mat-table [dataSource]="audienceCategory.mealSets">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"><a [routerLink]="['./meal-set', mealSetApi.getId(element['@id'])]">{{element.name}}</a></td>
          </ng-container>
          <ng-container matColumnDef="slug">
            <th mat-header-cell *matHeaderCellDef> Slug </th>
            <td mat-cell *matCellDef="let element"> {{element.slug}} </td>
          </ng-container>
          <ng-container matColumnDef="order">
            <th mat-header-cell *matHeaderCellDef> Display Order </th>
            <td mat-cell *matCellDef="let element; let i = index;">
              <div class="move-controls">
                <button mat-mini-fab type="button" color="primary" (click)="moveUp(i)" [disabled]="isFirst(i)"><mat-icon>arrow_upward</mat-icon></button>
                <button mat-mini-fab type="button" color="primary" (click)="moveDown(i)" [disabled]="isLast(i)"><mat-icon>arrow_downward</mat-icon></button>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="published">
            <th mat-header-cell *matHeaderCellDef> Published </th>
            <td mat-cell *matCellDef="let element; let i = index;">
              <mat-slide-toggle [checked]="element.published" (click)="togglePublish(i)">Published</mat-slide-toggle>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </mat-card-content>
    <mat-card-actions align="end">
      <a mat-raised-button color="primary" routerLink="./meal-set/create"><mat-icon aria-hidden="false">add_circle</mat-icon> Add Meal Set</a>
    </mat-card-actions>
  </mat-card>
</ng-container>
