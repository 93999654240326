import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { MealAudienceCategory } from '@interface/mealaudiencecategory.interface';
import { MealAudienceCategoryService } from '@service/api/mealaudiencecategory.service';
import { Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { EMPTY, Observable} from "rxjs";
import { catchError, map, first } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AudienceCategoryResolver implements Resolve<MealAudienceCategory> {
  constructor(private api: MealAudienceCategoryService, private router: Router, private location: Location) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MealAudienceCategory> {
    return this.api.get<MealAudienceCategory>(route.params.id).pipe(
      first(),
      catchError((data) => {
        this.router.navigate(["**"], {
          skipLocationChange: true}
        ).then(() => {
          this.location.replaceState(state.url);
        });
        return EMPTY;
      })
    );
  }
}
