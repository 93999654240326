<mat-card>
  <mat-card-title>Create Audience</mat-card-title>

  <mat-card-content>
  <form [formGroup]="form" (submit)=submit()>
    <form-audience formControlName="audience" [image_required]="true"></form-audience>
    <mat-card-actions align="end">
      <button mat-button color="primary" type="submit" [disabled]="!form.valid">
        <mat-icon aria-hidden="false" aria-label="Edit icon">add_circle</mat-icon> Create Audience
      </button>
    </mat-card-actions>
  </form>
  </mat-card-content>
</mat-card>
