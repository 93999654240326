import { Component, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AllergyService } from '@service/api/allergy.service';
import { Allergy } from '@interface/allergy.interface';
import { Observable, Subscription, EMPTY } from 'rxjs';
import { tap, map, first, catchError, shareReplay } from 'rxjs/operators';
import { HydraCollection } from '@interface/hydra/collection.interface';
import { FormAllergyComponent } from '@shared/forms/allergy/allergy.component';
import { HydraConstraint } from '@interface/hydra/constraint.interface';
import { Router } from "@angular/router";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { LooseObject } from '@interface/looseobject.interface';
import { DeleteDialog } from '@shared/dialog/delete/delete.dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'admin-allergy-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class AdminAllergyEditComponent {


  slug$ = this.route.snapshot.paramMap.get('id') || '';
  allergy$!: Observable<any>;
  allergy!: Allergy;

  form: FormGroup = this.fb.group({
    'allergy': []
  });

  @ViewChild(FormAllergyComponent) formAllergyComponent!: FormAllergyComponent;
  subscription$: Subscription[] = [];
  dialog$: Subscription = new Subscription();
  constructor(
    private route: ActivatedRoute,
    public api: AllergyService,
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    this.allergy$ = this.route.data.pipe(
      map(data => data.data),
      map((data:any) => {
        this.allergy = data;
        return {
          name: data.name
        }
      }),
      tap((data:any) => {
        let tmp = this.form.get('allergy') as FormGroup;
        tmp.patchValue(data);
      })
    );
  }
  submit(): void {
    if(this.form.valid) {
      let input = this.form.get('allergy') as FormGroup;
      let values = input.value;
      Object.assign(this.allergy, values);
      this._queryApi(this.allergy);
    }
  }


  _queryApi(values: Allergy) {
    this.subscription$.push(this.api.update<Allergy>(values).pipe(
      catchError((data:any) => {
        data = data.error;
        this._processConstraints(data);
        return EMPTY;
      })
    ).subscribe((data) => {
      this._snackBar.open("Allergy Updated.", 'close');
    }));
  }

  //Process error
  _processConstraints(data:HydraConstraint) {
    if(data['@type'] === 'ConstraintViolationList') {
      let obj:LooseObject = {};
      let childForm = this.formAllergyComponent.form
      if(childForm) {
        data.violations.forEach((element:any) => {
          let control = childForm.get(element.propertyPath) as FormControl;
          control.setErrors({
              server: element.message
          });
        });
        this.form.setErrors({error:true});
      }
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteDialog, {
      data: {
        title: 'Delete Allergy',
        message: 'Warning, this action cannot be undone!'
      },
    });

    this.subscription$.push(dialogRef.afterClosed().subscribe(result => {
     if(result) {
       this.subscription$.push(this.api.delete(this.allergy['@id'] as string).subscribe((response) => {
         this.router.navigate(['/admin/menu/allergy/']);
       }))
     }
   }));

  }

  ngOnDestroy(): void {
    this.subscription$.forEach(element => {
      element.unsubscribe();
    });
  }
}
