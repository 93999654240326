import { Component, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MealSetService } from '@service/api/mealset.service';
import { MealSet } from '@interface/mealset.interface';
import { LooseObject } from '@interface/looseobject.interface';
import { HydraCollection } from '@interface/hydra/collection.interface';
import { EMPTY, Observable, Subscription} from "rxjs";
import { FormMealSetComponent } from '@shared/forms/meal-set/meal-set.component';
import { HydraConstraint } from '@interface/hydra/constraint.interface';
import { Router, ActivatedRoute } from "@angular/router";
import { tap, map, first, catchError, shareReplay } from 'rxjs/operators';


@Component({
  selector: 'admin-mealSet-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class AdminMealSetCreateComponent implements OnDestroy {

  @ViewChild(FormMealSetComponent) formMealSetComponent!: FormMealSetComponent;
  form: FormGroup = this.fb.group({
    'mealSet': []
  });
  subscription$: Subscription = new Subscription();
  category$: Observable<any> =  new Observable();
  constructor(
    public api: MealSetService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.category$ = this.route.data.pipe(
      map(data => {return { mealAudienceCategory: data.data['@id'] }}),
      tap((data:any) => {
        let tmp = this.form.get('mealSet') as FormGroup;
        tmp.patchValue(data);
      })
    );
  }

  submit(): void {
    if(this.form.valid) {
      let input = this.form.get('mealSet') as FormGroup;
      let values = input.value;
      this._queryApi(values);
    }
  }

  _queryApi(values: MealSet) {
    this.subscription$ = this.api.create<MealSet>(values).pipe(
      catchError((data:any) => {
        data = data.error;
        this._processConstraints(data);
        return EMPTY;
      })
    ).subscribe((data:MealSet) => {
      const string = "/meal-set/create";
      const re = new RegExp(string, 'g')
      this.router.navigate([this.router.url.replace(re, '')]);
    });
  }

  //Process error
  _processConstraints(data:HydraConstraint) {
    if(data['@type'] === 'ConstraintViolationList') {
      let obj:LooseObject = {};
      let childForm = this.formMealSetComponent.form
      if(childForm) {
        data.violations.forEach((element:any) => {
          let control = childForm.get(element.propertyPath) as FormControl;
          control.setErrors({
              server: element.message
          });
        });
        this.form.setErrors({error:true});
      }
    }
  }

  ngOnDestroy():void {
    this.subscription$.unsubscribe();
  }
}
