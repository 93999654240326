import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'menu-item-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class AdminMenuItemIndexComponent {
  constructor(
    private router:Router,
    private route:ActivatedRoute
  ) {

  }
  doEdit(menuItem:any) {
    const explode:string|number[] = menuItem['@id'].split('/');
    const id = explode[(explode.length-1)];
    this.router.navigate(
      ['./', 'edit', id],
      { relativeTo: this.route }
    );
  }
}
