<mat-card>
  <mat-card-title>Menu Items</mat-card-title>
  <mat-card-subtitle>A menu item is a unique item on your menu. Menu items are relational to meal sets. If a menu item gets updated or deleted, those changes will apply across all event types, categories, and meal sets.</mat-card-subtitle>
  <mat-card-content>
    <app-item-search (item)="doEdit($event)"></app-item-search>
    <admin-menu-item-list></admin-menu-item-list>
  </mat-card-content>
  <mat-card-actions align="end">
    <a mat-raised-button color="primary" [routerLink]="['/admin/menu/menu-item/create']">
      <mat-icon aria-hidden="false" aria-label="Edit icon">add_circle</mat-icon>Create Menu Item
    </a>
  </mat-card-actions>
</mat-card>
