import { Component, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CategoryService } from '@service/api/category.service';
import { Category } from '@interface/category.interface';
import { Observable, Subscription, EMPTY } from 'rxjs';
import { tap, map, first, catchError, shareReplay } from 'rxjs/operators';
import { HydraCollection } from '@interface/hydra/collection.interface';
import { FormCategoryComponent } from '@shared/forms/category/category.component';
import { HydraConstraint } from '@interface/hydra/constraint.interface';
import { Router } from "@angular/router";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { LooseObject } from '@interface/looseobject.interface';
import { DeleteDialog } from '@shared/dialog/delete/delete.dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'admin-category-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class AdminCategoryEditComponent {


  slug$ = this.route.snapshot.paramMap.get('id') || '';
  category$!: Observable<any>;
  category!: Category;

  form: FormGroup = this.fb.group({
    'category': []
  });

  @ViewChild(FormCategoryComponent) formCategoryComponent!: FormCategoryComponent;
  subscription$: Subscription[] = [];
  dialog$: Subscription = new Subscription();
  constructor(
    private route: ActivatedRoute,
    public api: CategoryService,
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar

  ) {
    this.category$ = this.route.data.pipe(
      map(data => data.data),
      map((data:any) => {
        this.category = data;
        return {
          name: data.name
        }
      }),
      tap((data:any) => {
        let tmp = this.form.get('category') as FormGroup;
        tmp.patchValue(data);
      })
    );
  }
  submit(): void {
    if(this.form.valid) {
      let input = this.form.get('category') as FormGroup;
      let values = input.value;
      Object.assign(this.category, values);
      this._queryApi(this.category);
    }
  }


  _queryApi(values: Category) {
    this.subscription$.push(this.api.update<Category>(values).pipe(
      catchError((data:any) => {
        data = data.error;
        this._processConstraints(data);
        return EMPTY;
      })
    ).subscribe((data) => {
      this._snackBar.open("Menu Item Category Updated.", 'close');
    }));
  }

  //Process error
  _processConstraints(data:HydraConstraint) {
    if(data['@type'] === 'ConstraintViolationList') {
      let obj:LooseObject = {};
      let childForm = this.formCategoryComponent.form
      if(childForm) {
        data.violations.forEach((element:any) => {
          let control = childForm.get(element.propertyPath) as FormControl;
          control.setErrors({
              server: element.message
          });
        });
        this.form.setErrors({error:true});
      }
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteDialog,{
      data: {
        title: 'Delete Category',
        message: 'Warning, this action cannot be undone!'
      },
    });

    this.subscription$.push(dialogRef.afterClosed().subscribe(result => {
     if(result) {
       let group = this.form.get('category') as FormGroup;
       this.subscription$.push(this.api.delete(this.category['@id'] as string).subscribe((response) => {
         this.router.navigate(['/admin/menu/category/']);
       }))
     }
   }));

  }

  ngOnDestroy(): void {
    this.subscription$.forEach(element => {
      element.unsubscribe();
    });
  }
}
