<fieldset [formGroup]="form">
  <mat-form-field appearance="outline" class="full">
    <mat-label>Username</mat-label>
    <input matInput type="text" formControlName="username" id="name" required>
    <mat-error *ngIf="!isValid('username') && form.get('username')?.errors as errors">
      <div *ngIf="errors.required">This field is required</div>
      <div *ngIf="errors.minlength">This field must be at least {{errors.minlength.requiredLength}} characters</div>
      <div *ngIf="errors.maxlength">This field must not be longer than {{errors.maxlength.requiredLength}} characters</div>
      <div *ngIf="errors.server">{{errors.server}}</div>
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="full">
    <mat-label>Email</mat-label>
    <input matInput type="email" formControlName="email" id="name" required>
    <mat-error *ngIf="!isValid('email') && form.get('email')?.errors as errors">
      <div *ngIf="errors.required">This field is required</div>
      <div *ngIf="errors.minlength">This field must be at least {{errors.minlength.requiredLength}} characters</div>
      <div *ngIf="errors.maxlength">This field must not be longer than {{errors.maxlength.requiredLength}} characters</div>
      <div *ngIf="errors.server">{{errors.server}}</div>
    </mat-error>
  </mat-form-field>
  <div *ngFor="let role of roles; let i=index">
    <mat-slide-toggle [checked]="isChecked(role)" (toggleChange)="toggleChange(role)">{{role}}</mat-slide-toggle>
  </div>
</fieldset>
