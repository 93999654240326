import { Component, AfterViewInit } from '@angular/core';
/*
import { MealSetComponent } from '@menu/component/mealset/mealset.component';
import { MEALSETS } from '@mock/mealset.mockdata';
import {MealSet} from '@interface/mealset.interface';
import {Allergy} from '@interface/allergy.interface';
import {LoginService} from '@service/api/login.service';
import {AllergyService} from '@service/api/allergy.service';
import {AudienceService} from '@service/api/audience.service';
import { FormControl, FormBuilder, FormGroup, FormArray, Validator, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
*/

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  //mealSets:MealSet[] = [];

  constructor(
    //private login: LoginService,
    //private allergyApi: AllergyService,
    //private fb: FormBuilder,
    //private route: ActivatedRoute
  ) {}

  ngAfterViewInit() {
    /*
    this.route.fragment.subscribe(f => {
      if(f) {
        setTimeout(() => {
          const element = document.querySelector("[id='"+f+"']")
          if (element) {
            element.scrollIntoView(true)
          }
        }, 500);
      }
    })
    */
  }
}
