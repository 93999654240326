<mat-card *ngIf="inquiry$ | async as i">
  <mat-card-title>
    Inquiry #{{api.getId(i['@id'])}} - {{i.dateCreated | date:'medium'}}    
  </mat-card-title>
 
  <mat-card-content>
    <mat-divider inset="true"></mat-divider>    
    <h1>{{i.firstName}} {{i.lastName}}</h1>

    <table>
      <tbody>
        <tr>
          <td>Date Created</td><td>{{i.dateCreated | date:'medium'}}</td>
        </tr>
        <tr>
          <td>Phone</td><td>{{i.phone}}</td>
        </tr>
        <tr>
          <td>Email</td><td> <a href="mailto:{{i.email}}">{{i.email}}</a></td>
        </tr>
      </tbody>
    </table>
    <br/><br/>
    <h2>{{i.subject}}</h2>
    <div class="message-wrap">
      <p [innerText]="i.message"></p>
    </div>
  </mat-card-content>
</mat-card>
