import { Component, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DietaryPreferenceService } from '@service/api/dietary-preference.service';
import { DietaryPreference } from '@interface/dietarypreference.interface';
import { Observable, Subscription, EMPTY } from 'rxjs';
import { tap, map, first, catchError, shareReplay } from 'rxjs/operators';
import { HydraCollection } from '@interface/hydra/collection.interface';
import { FormDietaryPreferenceComponent } from '@shared/forms/dietary-preference/dietary-preference.component';
import { HydraConstraint } from '@interface/hydra/constraint.interface';
import { Router } from "@angular/router";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { LooseObject } from '@interface/looseobject.interface';
import { DeleteDialog } from '@shared/dialog/delete/delete.dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'admin-dietary-preference-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class AdminDietaryPreferenceEditComponent {


  slug$ = this.route.snapshot.paramMap.get('id') || '';
  dietaryPreference$!: Observable<any>;
  dietaryPreference!: DietaryPreference;

  form: FormGroup = this.fb.group({
    'dietaryPreference': []
  });

  @ViewChild(FormDietaryPreferenceComponent) formDietaryPreferenceComponent!: FormDietaryPreferenceComponent;
  subscription$: Subscription[] = [];
  dialog$: Subscription = new Subscription();
  constructor(
    private route: ActivatedRoute,
    public api: DietaryPreferenceService,
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar

  ) {
    this.dietaryPreference$ = this.route.data.pipe(
      map(data => data.data),
      map((data:any) => {
        this.dietaryPreference = data;
        return {
          name: data.name,
          description: data.description
        }
      }),
      tap((data:any) => {
        let tmp = this.form.get('dietaryPreference') as FormGroup;
        tmp.patchValue(data);
      })
    );
  }

  submit(): void {
    if(this.form.valid) {
      let input = this.form.get('dietaryPreference') as FormGroup;
      let values = input.value;
      values['@id'] = this.api.getEndpoint() + '/' + this.slug$;
      this._queryApi(values);
    }
  }


  _queryApi(values: DietaryPreference) {
    this.subscription$.push(this.api.update<DietaryPreference>(values).pipe(
      catchError((data:any) => {
        data = data.error;
        this._processConstraints(data);
        return EMPTY;
      })
    ).subscribe((data) => {
      this._snackBar.open("Dietary Preference Updated.", 'close');
    }));
  }

  //Process error
  _processConstraints(data:HydraConstraint) {
    if(data['@type'] === 'ConstraintViolationList') {
      let obj:LooseObject = {};
      let childForm = this.formDietaryPreferenceComponent.form
      if(childForm) {
        data.violations.forEach((element:any) => {
          let control = childForm.get(element.propertyPath) as FormControl;
          control.setErrors({
              server: element.message
          });
        });
        this.form.setErrors({error:true});
      }
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteDialog,{
      data: {
        title: 'Delete DietaryPreference',
        message: 'Warning, this action cannot be undone!'
      },
    });

    this.subscription$.push(dialogRef.afterClosed().subscribe(result => {
     if(result) {
       let group = this.form.get('dietaryPreference') as FormGroup;
       this.subscription$.push(this.api.delete(this.dietaryPreference['@id'] as string).subscribe((response) => {
         this.router.navigate(['/admin/menu/dietary-preference/']);
       }))
     }
   }));

  }

  ngOnDestroy(): void {
    this.subscription$.forEach(element => {
      element.unsubscribe();
    });
  }
}
