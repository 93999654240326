import { Injectable } from '@angular/core';
import { Breadcrumb } from '@interface/breadcrumb.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  bc:Breadcrumb[] = [];

  breadcrumbs = new BehaviorSubject<Breadcrumb[]>([]);

  add(crumb:Breadcrumb) {
    this.bc.push(crumb);
    this.breadcrumbs.next(this.bc);
  }
  clear() {
    this.bc = [];
    this.breadcrumbs.next(this.bc);
  }

  get breadcrumb() {
    return this.breadcrumbs;
  }


}
