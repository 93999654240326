import { Component, OnInit, OnDestroy, Input, forwardRef, ChangeDetectorRef } from '@angular/core';
import { NgControl, FormControl, FormBuilder, FormGroup, FormArray, Validator, Validators, ControlValueAccessor, AbstractControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LooseObject } from '@interface/looseobject.interface';

@Component({
  selector: 'form-nutrition-info',
  templateUrl: './nutrition.component.html',
  styleUrls: ['./nutrition.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormNutritionInfoComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormNutritionInfoComponent),
      multi: true
    }
  ]
})
export class FormNutritionInfoComponent implements ControlValueAccessor, OnDestroy, Validator {
  keys: LooseObject[] = [
    {
      name: 'Serving Size',
      id: 'servingSize'
    },
    {
      name: 'Calories',
      id: 'calories'
    },
    {
      name: 'Calories From Fat',
      id: 'caloriesFromFat'
    },
    {
      name: 'Total Fat',
      id: 'totalFat'
    },
    {
      name: 'Saturated Fat',
      id: 'saturatedFat'
    },
    {
      name: 'Trans Fat',
      id: 'transFat'
    },
    {
      name: 'Cholesterol',
      id: 'cholesterol'
    },
    {
      name: 'Sodium',
      id: 'sodium'
    },
    {
      name: 'Potassium',
      id: 'potassium'
    },
    {
      name: 'Total Carbohydrates',
      id: 'totalCarbohydrates'
    },
    {
      name: 'Dietary Fiber',
      id: 'dietaryFiber'
    },
    {
      name: 'Sugars',
      id: 'sugars'
    },
    {
      name: 'Vitamin A',
      id: 'vitaminA'
    },
    {
      name: 'Vitamin C',
      id: 'vitaminC'
    },
    {
      name: 'Calcium',
      id: 'calcium'
    },
    {
      name: 'Iron',
      id: 'iron'
    },
    {
      name: 'Magnesium',
      id: 'magnesium'
    },
    {
      name: 'Zinc',
      id: 'zinc'
    },
    {
      name: 'Copper',
      id: 'copper'
    },
    {
      name: 'Thiamin',
      id: 'thiamin'
    },
    {
      name: 'Riboflavin',
      id: 'riboflavin'
    },
    {
      name: 'Niacin',
      id: 'niacin'
    },
    {
      name: 'Vitamin B6',
      id: 'vitaminB6'
    },
    {
      name: 'Vitamin B12',
      id: 'vitaminB12'
    },
    {
      name: 'Vitamin DIU',
      id: 'vitaminDIU'
    }
  ];

  buildForm(): FormGroup {
    let obj:LooseObject = {};
    obj['@id'] = null;
    obj['@type'] = "NutritionInfo";
    this.keys.forEach((element:LooseObject) => {
      obj[element.id] = [0, [Validators.required]];
    });
    return this.fb.group(obj);
  }

  form: FormGroup = this.buildForm();

  onTouched: Function = () => {};
  onChanged: Function = () => {};

  onChangeSubs: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
  ) {}


  ngOnDestroy(): void {
    for (let sub of this.onChangeSubs) {
      sub.unsubscribe();
    }
  }

  registerOnChange(fn: any): void {
     this.onChangeSubs.push(this.form.valueChanges.subscribe(fn));
  }

  registerOnTouched(fn: any): void {
   this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.form.disable();
    }
    else {
      this.form.enable();
    }
  }

  writeValue(value: any): void {
    if (value) {
      this.form.setValue(value, {emitEvent: false});
    }
  }

  validate(control: AbstractControl) {
    return this.form.valid ? null : { valid: false, message: 'Invalid allergy' };
  }

  isValid(name: string): boolean {
    if(this.form.get(name)) {
      let control = this.form.get(name) as FormControl;
      return control.valid;
    }
    return false;
  }
}
