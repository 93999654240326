<fieldset [formGroup]="form" *ngIf="menuItems$ | async">
  <mat-card>
    <mat-card-title>Meal Set</mat-card-title>
    <mat-card-content>
      <mat-form-field appearance="outline" class="full">
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name" id="name" required>
        <mat-error *ngIf="!isValid('name') && form.get('name')?.errors as errors">
          <div *ngIf="errors.required">This field is required</div>
          <div *ngIf="errors.minlength">This field must be at least {{errors.minlength.requiredLength}} characters</div>
          <div *ngIf="errors.maxlength">This field must not be longer than {{errors.maxlength.requiredLength}} characters</div>
          <div *ngIf="errors.server">{{errors.server}}</div>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full">
        <mat-label>Tagline</mat-label>
        <textarea matInput formControlName="tagline" id="tagline" required></textarea>
        <mat-error *ngIf="!isValid('tagline') && form.get('tagline')?.errors as errors">
          <div *ngIf="errors.required">This field is required</div>
          <div *ngIf="errors.minlength">This field must be at least {{errors.minlength.requiredLength}} characters</div>
          <div *ngIf="errors.maxlength">This field must not be longer than {{errors.maxlength.requiredLength}} characters</div>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description" id="description"></textarea>
        <mat-error *ngIf="!isValid('description') && form.get('description')?.errors as errors">
          <div *ngIf="errors.minlength">This field must be at least {{errors.minlength.requiredLength}} characters</div>
          <div *ngIf="errors.maxlength">This field must not be longer than {{errors.maxlength.requiredLength}} characters</div>
        </mat-error>
      </mat-form-field>
      <input type="hidden" formControlName="mealAudienceCategory"/>

      <div class="image-select">
        <div class="image-path" *ngIf="image_path">
          <img [attr.src]="image_path" />
        </div>
        <label *ngIf="image_path" for="image" class="replace-image">Replace Image</label>
        <label *ngIf="!image_path" for="image" class="add-image">Add Image</label>

        <input type="file" accept="image/jpeg" #image id="image" required (change)="change($event)" (blur)="input_touched = true">
        <mat-error *ngIf="input_touched">
          <div *ngIf="file_errors.required">Image is required</div>
          <div *ngIf="file_errors.image_large">Image must not exceed {{image_max_size/1000}}kB</div>
          <div *ngIf="file_errors.image_dims">Image must be {{image_width}}x{{image_height}}px</div>
        </mat-error>
      </div>
      <mat-hint>Image must not exceed {{image_max_size/1000}}kB. </mat-hint>
      <mat-hint>Image must be {{image_width}}x{{image_height}}px</mat-hint>

      <br/>
      <!--
      <div>
        <div class="image-path" *ngIf="image_path">
          <img [attr.src]="image_path" />
        </div>
        <input type="file" accept="image/jpeg" #image id="image" required (change)="change($event)" (blur)="input_touched = true">
        <mat-error *ngIf="input_touched">
          <div *ngIf="file_errors.required">Image is required</div>
          <div *ngIf="file_errors.image_large">Image must not exceed {{image_max_size/1000}}kB</div>
          <div *ngIf="file_errors.image_dims">Image must be {{image_width}}x{{image_height}}px</div>
        </mat-error>
      </div>
      <input type="hidden" formControlName="displayOrder" id="displayOrder" pattern="[\d]+" required>
      -->
      </mat-card-content>
  </mat-card>
  <br/>
  <form-menu-item-options formControlName="mealSetIncludes"></form-menu-item-options>
  <br/>
  <mat-card class="group-builder">
    <mat-card-title>Group</mat-card-title>
    <mat-card-subtitle>Define your meal set type</mat-card-subtitle>
    <mat-card-content>
      <fieldset formArrayName="mealSetGroups">
        <div *ngFor="let group of mealSetGroups.controls; let i = index">
            <ng-container [formGroupName]="i">
              <div class="meal-set-group">
                <mat-form-field appearance="outline" class="full">
                  <mat-label>Type</mat-label>
                  <mat-select formControlName="type">
                    <mat-option value="fixed">Fixed Price - Price per guest</mat-option>
                    <mat-option value="variable">Variable price - Price per guest can vary per option </mat-option>
                    <mat-option value="a-la-carte">à la carte - Prices are defined by item quantity</mat-option>
                  </mat-select>
                </mat-form-field>
                <!--
                <mat-form-field appearance="outline" class="full">
                  <mat-label>Name</mat-label>
                  <input matInput type="text" formControlName="name">
                </mat-form-field>
                -->
                <input type="hidden"  pattern="[\d]+" required formControlName="displayOrder" [value]="i">
                <!--
                <mat-form-field appearance="outline" class="full">
                  <mat-label>Fine Print</mat-label>
                  <input matInput type="text" formControlName="finePrint">
                </mat-form-field>
                -->
                <mat-form-field appearance="outline" class="full" *ngIf="getTypeValue(i) == 'fixed'">
                  <mat-label>price</mat-label>
                  <input matInput type="number" pattern="^[0-9]+(\.[0-9]{1,2})?$" formControlName="price">
                </mat-form-field>
                <mat-form-field appearance="outline" class="full" *ngIf="getTypeValue(i) == 'fixed' || getTypeValue(i) == 'variable'">
                  <mat-label>Option Title</mat-label>
                  <input matInput type="text" formControlName="optionTitle">
                </mat-form-field>
                <mat-form-field appearance="outline" class="full" *ngIf="getTypeValue(i) == 'fixed' || getTypeValue(i) == 'variable'">
                  <mat-label>Option Rule</mat-label>
                  <input matInput type="text" formControlName="optionRule">
                </mat-form-field>

                <mat-card formArrayName="variants" *ngIf="getTypeValue(i) == 'variable'">
                  <mat-card-title>Variant Prices</mat-card-title>
                  <mat-card-content class="mat-card-nested">
                    <fieldset class="group-builder" *ngFor="let variant of getVariants(group).controls; let ib = index">
                        <mat-card [formGroupName]="ib">
                          <mat-card-title>Variant</mat-card-title>
                          <mat-card-content>
                            <mat-form-field appearance="outline" class="full">
                              <mat-label>Name</mat-label>
                              <input matInput type="text" formControlName="name">
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="full">
                              <mat-label>Description</mat-label>
                              <input matInput type="text" formControlName="description">
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="full">
                              <mat-label>Price</mat-label>
                              <input matInput type="number" formControlName="price">
                            </mat-form-field>
                          </mat-card-content>
                          <mat-card-actions>
                            <button mat-mini-fab type="button" class="right" color="warn" (click)="removeVariant(i, ib)">
                              <mat-icon>delete</mat-icon>
                            </button>
                          </mat-card-actions>
                        </mat-card>
                    </fieldset>
                  </mat-card-content>
                  <mat-card-actions align="end">
                    <button type="button" class="include" mat-raised-button color="primary" (click)="addVariant(i)">
                      <mat-icon aria-hidden="false" aria-label="Edit icon">add_circle</mat-icon> Add Variant
                    </button>
                  </mat-card-actions>
                </mat-card>
              </div>
              <mat-card>
                <mat-card-title>Meal Set Sections</mat-card-title>
                <mat-card-content class="mat-card-nested">
                  <ng-container formArrayName="mealSetSection">
                    <fieldset  *ngFor="let group of groupmealSetSection(i).controls; let b = index">
                      <!--class="group-builder"-->
                      <mat-card>
                        <mat-card-title>Section</mat-card-title>
                        <mat-card-content>
                          <legend>Section</legend>
                          <ng-container [formGroupName]="b">
                            <div class="meal-set-section">
                              <mat-form-field appearance="outline" class="full">
                                <mat-label>Name</mat-label>
                                <input matInput type="text" formControlName="name">
                              </mat-form-field>
                              <input type="hidden" pattern="[\d]+" required formControlName="displayOrder" [value]="b">
                              <mat-form-field appearance="outline" class="full">
                                <mat-label>Rules</mat-label>
                                <input matInput type="text" formControlName="rule">
                              </mat-form-field>
                              <!--
                              <mat-form-field appearance="outline" class="full">
                                <mat-label>Fine Print</mat-label>
                                <input matInput type="text" formControlName="finePrint">
                              </mat-form-field>
                              -->
                              <mat-card>
                                <mat-card-title>Section Items</mat-card-title>
                                <mat-card-content class="mat-card-nested">
                                  <ng-container formArrayName="sectionItems">

                                  <fieldset  *ngFor="let group of groupmealSetSectionItems(i, b).controls; let c = index">
                                    <mat-card>
                                      <mat-card-title>Item</mat-card-title>
                                      <mat-card-content>
                                        <div class="meal-set-section" [formGroupName]="c">
                                          <ng-container *ngIf="itemIsSelected(groupmealSetSectionItems(i, b).controls[c]); else showForm">
                                            <ng-container *ngIf="getMenuItem(i,b,c) as menuItem">
                                              <h2>{{menuItem.name}}</h2>
                                              <p>{{menuItem.description}}</p>
                                            </ng-container>
                                            <mat-form-field appearance="outline" class="full">
                                              <mat-label>Price</mat-label>
                                              <input matInput type="number" formControlName="price" pattern="^[0-9]+(\.[0-9]{1,2})?$" step="0.01">
                                            </mat-form-field>
                                          <mat-slide-toggle #slideToggle
                                            [checked]="isOverrideChecked(groupmealSetSectionItems(i, b).controls[c])"
                                            (change)="doOverride($event, i, b, c)"
                                          >Override default title or description</mat-slide-toggle>
                                          <br/>
                                          <br/>
                                          <ng-container *ngIf="isOverrideChecked(groupmealSetSectionItems(i, b).controls[c]) || slideToggle.checked">
                                            <mat-form-field appearance="outline" class="full">
                                              <mat-label>Name</mat-label>
                                              <input matInput type="text" formControlName="name">
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="full">
                                              <mat-label>Description</mat-label>
                                              <input matInput type="text" formControlName="description">
                                            </mat-form-field>
                                          </ng-container>
                                          <input type="hidden" formControlName="displayOrder" [value]="c">

                                        </ng-container>
                                        <ng-template #showForm>
                                          <app-item-search (item)="setMenuItemSelected(i,b,c, $event)"></app-item-search>
                                        </ng-template>
                                        <!--
                                        <ng-container [formGroupName]="c" *ngIf="menuItems$ | async">
                                          <mat-form-field appearance="outline" class="full">
                                            <mat-select appearance="fill" formControlName="menuItem">
                                              <mat-option *ngFor="let menuItem of menuItems;" [value]="menuItem['@id']" >
                                                {{menuItem.name}}
                                              </mat-option>
                                            </mat-select>
                                          </mat-form-field>
                                          <ng-container *ngIf="itemIsSelected(groupmealSetSectionItems(i, b).controls[c])">
                                            <mat-slide-toggle #slideToggle
                                              [checked]="isOverrideChecked(groupmealSetSectionItems(i, b).controls[c])"
                                              (change)="doOverride($event, i, b, c)"
                                            >Override Defaults</mat-slide-toggle>
                                            <ng-container *ngIf="isOverrideChecked(groupmealSetSectionItems(i, b).controls[c]) || slideToggle.checked">
                                              <mat-form-field appearance="outline" class="full">
                                                <mat-label>Name</mat-label>
                                                <input matInput type="text" formControlName="name">
                                              </mat-form-field>
                                              <mat-form-field appearance="outline" class="full">
                                                <mat-label>Description</mat-label>
                                                <input matInput type="text" formControlName="description">
                                              </mat-form-field>
                                            </ng-container>
                                            <input type="hidden" formControlName="displayOrder" [value]="c">
                                            <mat-form-field appearance="outline" class="full">
                                              <mat-label>Price</mat-label>
                                              <input matInput type="number" formControlName="price" pattern="^[0-9]+(\.[0-9]{1,2})?$" step="0.01">
                                            </mat-form-field>
                                          </ng-container>
                                        </ng-container>
                                        -->
                                        </div>
                                      </mat-card-content>
                                      <mat-card-actions class="rightCol">
                                        <ng-container *ngIf="groupmealSetSectionItems(i, b).controls.length > 1">
                                          <button mat-mini-fab type="button" color="primary" [disabled]="c==0" (click)="moveUp(groupmealSetSectionItems(i, b), c)"><mat-icon>arrow_upward</mat-icon></button>
                                          <button mat-mini-fab type="button" color="primary" [disabled]="c==(groupmealSetSectionItems(i, b).controls.length-1)" (click)="moveDown(groupmealSetSectionItems(i, b), c)"><mat-icon>arrow_downward</mat-icon></button>
                                        </ng-container>
                                        <button mat-mini-fab type="button" color="warn" class="right" (click)="removeItem(groupmealSetSectionItems(i, b), c)">
                                          <mat-icon>delete</mat-icon>
                                        </button>
                                      </mat-card-actions>
                                    </mat-card>
                                  </fieldset>
                                  </ng-container>
                                </mat-card-content>
                                <mat-card-actions align="end">
                                  <button type="button" class="include" mat-raised-button color="primary" (click)="addItem(i, b)">
                                    <mat-icon aria-hidden="false" aria-label="Edit icon">add_circle</mat-icon> Add Item
                                  </button>
                                </mat-card-actions>
                              </mat-card>
                            </div>
                          </ng-container>
                        </mat-card-content>
                        <mat-card-actions class="rightCol">
                          <ng-container *ngIf="groupmealSetSection(i).controls.length > 1">
                            <button mat-mini-fab type="button" color="primary" [disabled]="b==0" (click)="moveUp(groupmealSetSection(i), b)"><mat-icon>arrow_upward</mat-icon></button>
                            <button mat-mini-fab type="button" color="primary" [disabled]="b==(groupmealSetSection(i).controls.length-1)" (click)="moveDown(groupmealSetSection(i), b)"><mat-icon>arrow_downward</mat-icon></button>
                          </ng-container>
                          <button mat-mini-fab type="button" color="warn" class="right" (click)="removeItem(groupmealSetSection(i), b)">
                            <mat-icon>delete</mat-icon>
                          </button>
                        </mat-card-actions>
                      </mat-card>

                    </fieldset>
                    <div class="move-controls" *ngIf="mealSetGroups.controls.length > 1">
                      <button type="button"  [disabled]="i==0" (click)="moveUp(mealSetGroups, i)"><mat-icon>arrow_upward</mat-icon></button>
                      <button type="button"  [disabled]="i==(mealSetGroups.controls.length-1)" (click)="moveDown(mealSetGroups, i)"><mat-icon>arrow_downward</mat-icon></button>
                    </div>
                  </ng-container>
                </mat-card-content>
                <mat-card-actions  align="end">
                  <button class="include" type="button" mat-raised-button color="primary" (click)="addMealset(i)">
                    <mat-icon aria-hidden="false" aria-label="Edit icon">add_circle</mat-icon> Add Section
                  </button>
                </mat-card-actions>
              </mat-card>
            </ng-container>
        </div>
      </fieldset>
    </mat-card-content>
  </mat-card>
  <mat-slide-toggle formControlName="published">Published</mat-slide-toggle>
</fieldset>
