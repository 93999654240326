import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from './breadcrumb.service';
import { Breadcrumb } from '@interface/breadcrumb.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  breadcrumbs:BehaviorSubject<Breadcrumb[]>;

  constructor(private bc: BreadcrumbService) {
    this.breadcrumbs = this.bc.breadcrumbs;
  }

  ngOnInit(): void {
  }

}
