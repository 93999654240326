<fieldset [formGroup]="form">
  <mat-form-field appearance="outline" class="full">
    <mat-label>Name</mat-label>
    <input matInput type="text" formControlName="name" id="name" required>
    <mat-error *ngIf="!isValid('name') && form.get('name')?.errors as errors">
      <div *ngIf="errors.required">This field is required</div>
      <div *ngIf="errors.minlength">This field must be at least {{errors.minlength.requiredLength}} characters</div>
      <div *ngIf="errors.maxlength">This field must not be longer than {{errors.maxlength.requiredLength}} characters</div>
      <div *ngIf="errors.server">{{errors.server}}</div>
    </mat-error>
  </mat-form-field>
  <input type="hidden" formControlName="displayOrder" id="displayOrder">
  <mat-form-field appearance="outline" class="full">
    <mat-label>Slug</mat-label>
    <input matInput type="text" formControlName="slug" id="slug" pattern="[a-zA-Z0-9\-]*" required/>
    <mat-error *ngIf="!isValid('slug') && form.get('slug')?.errors as errors">
      <div *ngIf="errors.required">This field is required</div>
      <div *ngIf="errors.minlength">This field must be at least {{errors.minlength.requiredLength}} characters</div>
      <div *ngIf="errors.maxlength">This field must not be longer than {{errors.maxlength.requiredLength}} characters</div>
      <div *ngIf="errors.server">{{errors.server}}</div>
    </mat-error>
  </mat-form-field>
  <!--
  <mat-form-field appearance="outline" class="full" *ngIf="audience$ | async as obj">
    <mat-label>Audience</mat-label>
    <mat-select formControlName="menuAudience">
      <mat-option *ngFor="let audience of obj" [value]="audience['@id']">{{audience.name}}</mat-option>
    </mat-select>
    <mat-error *ngIf="!isValid('menuAudience') && form.get('menuAudience')?.errors as errors">
      <div *ngIf="errors.required">This field is required</div>
    </mat-error>
  </mat-form-field>
  -->
  <input type="hidden" formControlName="menuAudience"/> 
  <mat-slide-toggle formControlName="published">Published</mat-slide-toggle>
</fieldset>
