import { Component, OnInit } from '@angular/core';
import { MealAudienceCategoryService } from '@service/api/mealaudiencecategory.service';
import { MealAudienceCategory } from '@interface/mealaudiencecategory.interface';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HydraCollection } from '@interface/hydra/collection.interface';


@Component({
  selector: 'admin-audience-category-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class AdminAudienceCategoryListComponent {
  api$:Observable<HydraCollection> = this.api.getAll();
  index: number = 0;

  constructor(public api: MealAudienceCategoryService) { }

  handlePage(event:any) {
    this.index = event.pageIndex;
    this.api$ = this.api.getAll({
      page: this.getPage()
    });
  }

  getPage(): number {
    return this.index+1;
  }
  getIndex(): number {
    return this.index;
  }


}
