import { Component, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MealSetService } from '@service/api/mealset.service';
import { MealSet } from '@interface/mealset.interface';
import { Observable, Subscription, EMPTY } from 'rxjs';
import { tap, map, first, catchError, shareReplay } from 'rxjs/operators';
import { HydraCollection } from '@interface/hydra/collection.interface';
import { FormMealSetComponent } from '@shared/forms/meal-set/meal-set.component';
import { HydraConstraint } from '@interface/hydra/constraint.interface';
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LooseObject } from '@interface/looseobject.interface';
import { DeleteDialog } from '@shared/dialog/delete/delete.dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { BreadcrumbService } from '../../breadcrumb/breadcrumb.service';


@Component({
  selector: 'admin-meal-set-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class AdminMealSetEditComponent {


  slug$ = this.route.snapshot.paramMap.get('mealsetId') || '';
  mealSet$!: Observable<any>;
  form: FormGroup = this.fb.group({
    'mealSet': null
  });
  mealSet!:MealSet;
  @ViewChild(FormMealSetComponent) formMealSetComponent!: FormMealSetComponent;
  subscription$: Subscription[] = [];
  dialog$: Subscription = new Subscription();
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: MealSetService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public breadcrumbs: BreadcrumbService,
    private _snackBar: MatSnackBar

  ) {
    this.mealSet$ = this.route.data.pipe(
      map(data => data.data),
      map((data:any) => {
        this.mealSet = data;

        this.breadcrumbs.clear();

        this.breadcrumbs.add({
          label: 'Menu',
          url: '/admin/menu/audience',
        });
        
        this.breadcrumbs.add({
          label: data.mealAudienceCategory.menuAudience.name,
          url: '/admin/menu/audience/' + data.mealAudienceCategory.menuAudience.slug,
        })


        this.breadcrumbs.add({
          label: data.mealAudienceCategory.name,
          url: '/admin/menu/audience/' + data.mealAudienceCategory.menuAudience.slug + '/' + data.mealAudienceCategory.slug,
        })

        this.breadcrumbs.add({
          label: data.name,
          url: '/admin/menu/audience/' + data.mealAudienceCategory.menuAudience.slug + '/' + data.mealAudienceCategory.slug,
        })
        

        return {
          name: data.name,
          tagline: data.tagline,
          description: data.description || null,
          published: data.published,
          displayOrder: data.displayOrder,
          mealSetIncludes: data.mealSetIncludes.map((x:any) => {
            return {
              '@id': x['@id'],
              'displayOrder': x.displayOrder,
              'menuItem': x.menuItem['@id']
            }
          }),
          mealAudienceCategory: data.mealAudienceCategory['@id'],
          mealSetGroups: data.mealSetGroups.map((x:any) => {
            let tmp = this.dropHydra(x);
            tmp.variants = x.variants.map((x:any) => {
              return this.dropHydra(x);
            });
            tmp.mealSetSection = x.mealSetSection.map((x:any) => {
             let tmp = this.dropHydra(x);
             tmp.sectionItems = tmp.sectionItems.map((x:any) => {
               x.menuItem = x.menuItem['@id'];
               return this.dropHydra(x);
             })
             return tmp;
            })
            return tmp;
          })
        }
      }),
      tap((data:any) => {
        let tmp = this.form.get('mealSet') as FormGroup;
        tmp.patchValue(data);
      })
    );
  }

  dropHydra(x:any) {
    delete x['@id'];
    delete x['@type'];
    return x;
  }

  submit(): void {
    if(this.form.valid) {
      let input = this.form.get('mealSet') as FormGroup;
      let values = input.value;
      values['@id'] = this.api.getEndpoint() + '/' + this.slug$;
      this._queryApi(values);
    }
  }


  _queryApi(values: MealSet) {
    this.subscription$.push(this.api.update<MealSet>(values).pipe(
      catchError((data:any) => {
        data = data.error;
        this._processConstraints(data);
        return EMPTY;
      })
    ).subscribe((data) => {
      this._snackBar.open("Meal Set Updated.", 'close');
    }));
  }

  //Process error
  _processConstraints(data:HydraConstraint) {
    if(data['@type'] === 'ConstraintViolationList') {
      let obj:LooseObject = {};
      let childForm = this.formMealSetComponent.form
      if(childForm) {
        data.violations.forEach((element:any) => {
          let control = childForm.get(element.propertyPath) as FormControl;
          if(control) {
            control.setErrors({
              server: element.message
            });
            control.markAsTouched();
          }
        });
        this.form.setErrors({error:true});
      }
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteDialog, {
      data: {
        title: 'Delete Auience',
        message: 'Warning, this action cannot be undone!'
      },
    });

    this.subscription$.push(dialogRef.afterClosed().subscribe(result => {
     if(result) {
       this.subscription$.push(this.api.delete(this.mealSet['@id'] as string).subscribe((response) => {
         const string = "/meal-set/[0-9]+";
         const re = new RegExp(string, 'g')
         this.router.navigate([this.router.url.replace(re, '')]);
       }))
     }
   }));

  }

  ngOnDestroy(): void {
    this.subscription$.forEach(element => {
      element.unsubscribe();
    });
  }

}
