import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { Category } from '@interface/category.interface';
import { CategoryService } from '@service/api/category.service';
import { Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { EMPTY, Observable} from "rxjs";
import { catchError, map, first } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CategoryResolver implements Resolve<Category> {
  constructor(private api: CategoryService, private router: Router, private location: Location) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Category> {
    return this.api.get<Category>(route.params.id).pipe(
      first(),
      catchError((data) => {
        this.router.navigate(["**"], {
          skipLocationChange: true}
        ).then(() => {
          this.location.replaceState(state.url);
        });
        return EMPTY;
      })
    );
  }
}
