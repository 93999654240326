<ng-container *ngIf="allergies$ | async as obj">
  <ng-container [formGroup]="form">
    <ng-container formArrayName="allergies">
      <mat-list>
        <ng-container *ngFor="let allergy of obj; index as i">
          <mat-list-item>
            <mat-slide-toggle  [formControlName]="i" > {{ allergy.name }} </mat-slide-toggle>
          </mat-list-item>
        </ng-container>
      </mat-list>
    </ng-container>
  </ng-container>
</ng-container>
