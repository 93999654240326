<app-breadcrumb></app-breadcrumb>
<ng-container *ngIf="audience$ | async">
  <mat-card>
    <mat-card-title>Edit event type</mat-card-title>
    <mat-card-content>
      <form [formGroup]="form" (submit)=submit()>
        <form-audience formControlName="audience" [image]="audience.image"></form-audience>
        <mat-card-actions align="end">
          <button type="submit" mat-raised-button color="primary" [disabled]="!form.valid"><mat-icon aria-hidden="false">save</mat-icon> Update event type</button>
          <button type="button" mat-raised-button color="warn" (click)="openDialog()"><mat-icon aria-hidden="false">delete</mat-icon> Delete event type</button>
        </mat-card-actions>
      </form>
    </mat-card-content>
  </mat-card>
    <section>
      <mat-card>
        <mat-card-title>Categories</mat-card-title>
        <mat-card-subtitle>Categories are made up of a collection of meal sets</mat-card-subtitle>
        <mat-card-content>
          <div *ngIf="categories$ | async">
            <table mat-table [dataSource]="collection">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element"><a [routerLink]="[element.slug]">{{element.name}}</a></td>
              </ng-container>
              <ng-container matColumnDef="slug">
                <th mat-header-cell *matHeaderCellDef> Slug </th>
                <td mat-cell *matCellDef="let element"> {{element.slug}} </td>
              </ng-container>
              <ng-container matColumnDef="order">
                <th mat-header-cell *matHeaderCellDef> Display Order </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                  <div class="move-controls">
                    <button type="button" mat-mini-fab color="primary" (click)="moveUp(i)" [disabled]="isFirst(i)"><mat-icon>arrow_upward</mat-icon></button>
                    <button type="button" mat-mini-fab color="primary" (click)="moveDown(i)" [disabled]="isLast(i)"><mat-icon>arrow_downward</mat-icon></button>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="published">
                <th mat-header-cell *matHeaderCellDef> Published </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                  <mat-slide-toggle [checked]="element.published" (click)="togglePublish(i)">Published</mat-slide-toggle>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </mat-card-content>
        <mat-card-actions align="end">
          <button mat-button type="button" mat-raised-button color="primary" (click)="addCategory()">
            <mat-icon aria-hidden="false" aria-label="Edit icon">add_circle</mat-icon> Add Category
          </button>
        </mat-card-actions>
      </mat-card>
    </section>
</ng-container>
