import { Component, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AudienceService } from '@service/api/audience.service';
import { MenuAudience } from '@interface/audience.interface';
import { MealAudienceCategory } from '@interface/mealaudiencecategory.interface';
import { MealAudienceCategoryService } from '@service/api/mealaudiencecategory.service';
import { Observable, Subscription, EMPTY } from 'rxjs';
import { tap, map, first, catchError, shareReplay } from 'rxjs/operators';
import { HydraCollection } from '@interface/hydra/collection.interface';
import { FormAudienceComponent } from '@shared/forms/audience/audience.component';
import { HydraConstraint } from '@interface/hydra/constraint.interface';
import { Router } from "@angular/router";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { LooseObject } from '@interface/looseobject.interface';
import { DeleteDialog } from '@shared/dialog/delete/delete.dialog';
import { AudienceCategoryCreateDialogComponent } from '@shared/dialog/create-audience-category-dialog/create.component';
import {MatTable} from '@angular/material/table';
import { BreadcrumbService } from '../../breadcrumb/breadcrumb.service';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'admin-audience-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class AdminAudienceEditComponent {


  slug$ = this.route.snapshot.paramMap.get('id') || '';
  audience$!: Observable<any>;
  form: FormGroup = this.fb.group({
    'audience': []
  });
  audience!:MenuAudience;
  @ViewChild(FormAudienceComponent) formAudienceComponent!: FormAudienceComponent;
  @ViewChild(MatTable) table!: MatTable<MenuAudience>;

  subscription$: Subscription[] = [];
  dialog$: Subscription = new Subscription();
  categories$:Observable<HydraCollection> = new Observable();
  collection!:MealAudienceCategory[];
  displayedColumns: string[] = ['name', 'slug', 'published', 'order']


  constructor(
    private route: ActivatedRoute,
    private api: AudienceService,
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    public dialog2: MatDialog,
    private apiCategory: MealAudienceCategoryService,
    private breadcrumbs: BreadcrumbService,
    private _snackBar: MatSnackBar
  ) {
    this.audience$ = this.route.data.pipe(
      map(data => data.data),
      map((data:any) => {

        this.breadcrumbs.clear();

        this.breadcrumbs.add({
          label: 'Menu',
          url: '/admin/menu/audience',
        });

        this.breadcrumbs.add({
          label: data.name,
          url: '/admin/menu/audience/' + data.slug,
        })

        this.audience = data;
        return {
          name: data.name,
          description: data.description,
          published: data.published,
          displayOrder: data.displayOrder,
          slug: data.slug
        }
      }),
      tap((data:any) => {
        let tmp = this.form.get('audience') as FormGroup;
        this.categories$ = this.apiCategory.getAll({pagination: false, audience: data.slug}).pipe(
          map((data:HydraCollection) => {
              this.collection = data['hydra:member']
              return data;
          })
        )
        tmp.patchValue(data);
      })
    );
  }

  submit(): void {
    if(this.form.valid) {
      let input = this.form.get('audience') as FormGroup;
      let values = input.value;
      values['@id'] = this.api.getEndpoint() + '/' + this.slug$;
      this._queryApi(values);
    }
  }


  _queryApi(values: MenuAudience) {
    this.subscription$.push(this.api.update<MenuAudience>(values).pipe(
      catchError((data:any) => {
        data = data.error;
        this._processConstraints(data);
        return EMPTY;
      })
    ).subscribe((data) => {
      this._snackBar.open("Audience Updated.", 'close');
    }));
  }

  //Process error
  _processConstraints(data:HydraConstraint) {
    if(data['@type'] === 'ConstraintViolationList') {
      let obj:LooseObject = {};
      let childForm = this.formAudienceComponent.form
      if(childForm) {
        data.violations.forEach((element:any) => {
          let control = childForm.get(element.propertyPath) as FormControl;
          control.setErrors({
            server: element.message
          });
        });
        this.form.setErrors({error:true});
      }
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteDialog, {
      data: {
        title: 'Delete Auience',
        message: 'Warning, this action will delete all CATEGORIES and MEALSETS under this Audience. This action cannot be undone!'
      },
    });

    this.subscription$.push(dialogRef.afterClosed().subscribe(result => {
     if(result) {
       this.subscription$.push(this.api.delete(this.audience['@id'] as string).subscribe((response) => {
         this.router.navigate(['/admin/menu/audience/']);
       }));
     }
   }));

  }

  addCategory(): void {
    const dialogRef = this.dialog.open(AudienceCategoryCreateDialogComponent, {
      width: '500px',
      data: {
        title: 'Create Category',
        audience: this.audience
      },
    });

    this.subscription$.push(dialogRef.afterClosed().subscribe(result => {
        if(result) {
          this.collection.push(
            result.data
          );
          this.table.renderRows();

        }
      }
    ));
  }

  isFirst(i:number): boolean {
    if(i>0)
      return false;
    return true;
  }
  isLast(i:number): boolean {
    if(++i>=this.collection.length)
      return true;
    return false;
  }


  moveUp(i:number) {
    if(i <= 0) {
      return;
    }
    let iold:number = i;
    let inew:number = --i;
    [this.collection[iold], this.collection[inew]] = [this.collection[inew], this.collection[iold]]

    this.collection[inew].displayOrder = inew;
    this.collection[iold].displayOrder = iold;

    this.updateDisplayOrder(inew);
    this.updateDisplayOrder(iold);
    this.table.renderRows();

  }

  updateDisplayOrder(a:number) {
    const subs = this.apiCategory.update({
      '@id': this.collection[a]['@id'],
      'displayOrder': this.collection[a].displayOrder
    }).subscribe();

    this.subscription$.push(subs);
  }

  togglePublish(a:number) {
    this.collection[a].published = (this.collection[a].published) ? true : false;

    const subs = this.apiCategory.update({
      '@id': this.collection[a]['@id'],
      'published': (this.collection[a].published) ? false : true
    }).subscribe();
  }

  moveDown(i:number) {
    if(i >= this.collection.length) {
      return;
    }
    let iold:number = i;
    let inew:number = ++i;
    [this.collection[iold], this.collection[inew]] = [this.collection[inew], this.collection[iold]]

    this.collection[inew].displayOrder = inew;
    this.collection[iold].displayOrder = iold;

    this.updateDisplayOrder(inew);
    this.updateDisplayOrder(iold);
    this.table.renderRows();

  }


  ngOnDestroy(): void {
    this.subscription$.forEach(element => {
      element.unsubscribe();
    });
  }

}
