import { Component, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MealAudienceCategoryService } from '@service/api/mealaudiencecategory.service';
import { MealSetService } from '@service/api/mealset.service';
import { MealAudienceCategory } from '@interface/mealaudiencecategory.interface';
import { MealSet } from '@interface/mealset.interface';
import { Observable, Subscription, EMPTY } from 'rxjs';
import { tap, map, first, catchError, shareReplay } from 'rxjs/operators';
import { HydraCollection } from '@interface/hydra/collection.interface';
import { FormAudienceCategoryComponent } from '@shared/forms/audience-category/audience-category.component';
import { HydraConstraint } from '@interface/hydra/constraint.interface';
import { Router } from "@angular/router";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { LooseObject } from '@interface/looseobject.interface';
import { DeleteDialog } from '@shared/dialog/delete/delete.dialog';
import {MatTable} from '@angular/material/table';
import { BreadcrumbService } from '../../breadcrumb/breadcrumb.service';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'admin-audience-category-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class AdminAudienceCategoryEditComponent {

  slug$: string|number = '';
  audienceCategory$!: Observable<any>;
  form: FormGroup = this.fb.group({
    'audienceCategory': []
  });
  audienceCategory!:MealAudienceCategory;
  @ViewChild(FormAudienceCategoryComponent) formAudienceCategoryComponent!: FormAudienceCategoryComponent;
  @ViewChild(MatTable) table!: MatTable<MealSet>;

  displayedColumns: string[] = ['name', 'published', 'order']

  subscription$: Subscription[] = [];
  dialog$: Subscription = new Subscription();
  constructor(
    private route: ActivatedRoute,
    public api: MealAudienceCategoryService,
    public mealSetApi: MealSetService,
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    public breadcrumbs: BreadcrumbService,
    private _snackBar: MatSnackBar

  ) {
    this.audienceCategory$ = this.route.data.pipe(
      map(data => data.data),
      map((data:any) => {

        this.breadcrumbs.clear();

        this.breadcrumbs.add({
          label: 'Menu',
          url: '/admin/menu/audience',
        });

        this.breadcrumbs.add({
          label: data.menuAudience.name,
          url: '/admin/menu/audience/' + data.menuAudience.slug,
        })

        this.breadcrumbs.add({
          label: data.name,
          url: '/admin/menu/audience/' + data.menuAudience.slug + '/' + data.slug,
        })


        this.audienceCategory = data;
        this.slug$ = this.api.getId(data['@id']);
        return {
          name: data.name,
          description: data.description,
          published: data.published,
          displayOrder: data.displayOrder,
          menuAudience: data.menuAudience['@id'] as string,
          slug: data.slug
        }
      }),
      tap((data:any) => {
        let tmp = this.form.get('audienceCategory') as FormGroup;
        tmp.patchValue(data);
      })
    );
  }

  submit(): void {
    if(this.form.valid) {
      let input = this.form.get('audienceCategory') as FormGroup;
      let values = input.value;
      values['@id'] = this.api.getEndpoint() + '/' + this.slug$;
      this._queryApi(values);
    }
  }


  _queryApi(values: MealAudienceCategory):void  {
    this.subscription$.push(this.api.update<MealAudienceCategory>(values).pipe(
      catchError((data:any) => {
        data = data.error;
        this._processConstraints(data);
        return EMPTY;
      })
    ).subscribe((data) => {
      this._snackBar.open("Audience Category Updated.", 'close');
    }));
  }

  //Process error
  _processConstraints(data:HydraConstraint):void {
    if(data['@type'] === 'ConstraintViolationList') {
      let obj:LooseObject = {};
      let childForm = this.formAudienceCategoryComponent.form
      if(childForm) {
        data.violations.forEach((element:any) => {
          let control = childForm.get(element.propertyPath) as FormControl;
          control.setErrors({
            server: element.message
          });
        });
        this.form.setErrors({error:true});
      }
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteDialog, {
      data: {
        title: 'Delete Category',
        message: 'Warning, this action will delete ALL MEAL SETS under this category. This cannot be undone!'
      },
    });

    this.subscription$.push(dialogRef.afterClosed().subscribe(result => {
     if(result) {
       this.subscription$.push(this.api.delete(this.audienceCategory['@id'] as string).subscribe((response) => {
         const string = "/" + this.audienceCategory.slug + '$';
         const re = new RegExp(string, 'g')
         this.router.navigate([this.router.url.replace(re, '')]);
       }))
     }
   }));

  }
  ngOnDestroy(): void {
    this.subscription$.forEach(element => {
      element.unsubscribe();
    });
  }


  isFirst(i:number): boolean {
    if(i>0)
      return false;
    return true;
  }
  isLast(i:number): boolean {
    if(++i>=this.audienceCategory.mealSets.length)
      return true;
    return false;
  }


  moveUp(i:number): void  {
    if(i <= 0) {
      return;
    }
    let iold:number = i;
    let inew:number = --i;
    [this.audienceCategory.mealSets[iold], this.audienceCategory.mealSets[inew]] = [this.audienceCategory.mealSets[inew], this.audienceCategory.mealSets[iold]]

    this.audienceCategory.mealSets[inew].displayOrder = inew;
    this.audienceCategory.mealSets[iold].displayOrder = iold;

    this.updateDisplayOrder(inew);
    this.updateDisplayOrder(iold);
    this.table.renderRows();

  }

  updateDisplayOrder(a:number): void  {
    const subs = this.mealSetApi.update({
      '@id': this.audienceCategory.mealSets[a]['@id'],
      'displayOrder': this.audienceCategory.mealSets[a].displayOrder
    }).subscribe();

    this.subscription$.push(subs);
  }

  moveDown(i:number): void {
    if(i >= this.audienceCategory.mealSets.length) {
      return;
    }
    let iold:number = i;
    let inew:number = ++i;
    [this.audienceCategory.mealSets[iold], this.audienceCategory.mealSets[inew]] = [this.audienceCategory.mealSets[inew], this.audienceCategory.mealSets[iold]]

    this.audienceCategory.mealSets[inew].displayOrder = inew;
    this.audienceCategory.mealSets[iold].displayOrder = iold;

    this.updateDisplayOrder(inew);
    this.updateDisplayOrder(iold);
    this.table.renderRows();
  }

  togglePublish(a:number) {
    this.audienceCategory.mealSets[a].published = (this.audienceCategory.mealSets[a].published) ? true : false;
    const subs = this.mealSetApi.update({
      '@id': this.audienceCategory.mealSets[a]['@id'],
      'published': (this.audienceCategory.mealSets[a].published) ? false : true
    }).subscribe();
  }



  getId(id: any):any {
    return this.api.getId(id);
  }


}
