<fieldset [formGroup]="form">
  <mat-form-field appearance="outline" class="full">
    <mat-label>Name</mat-label>
    <input matInput type="text" formControlName="name" id="name" required>
    <mat-error *ngIf="!isValid('name') && form.get('name')?.errors as errors">
      <div *ngIf="errors.required">This field is required</div>
      <div *ngIf="errors.minlength">This field must be at least {{errors.minlength.requiredLength}} characters</div>
      <div *ngIf="errors.maxlength">This field must not be longer than {{errors.maxlength.requiredLength}} characters</div>
      <div *ngIf="errors.server">{{errors.server}}</div>
    </mat-error>
  </mat-form-field>
</fieldset>
