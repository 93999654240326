import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'admin-audience-category-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class AdminAudienceCategoryIndexComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
