<ng-container *ngIf="audience$ | async as obj">
  <mat-form-field appearance="fill">
    <mat-label>Audience</mat-label>
      <mat-select (selectionChange)="selectedAudience($event)" [formControl]="audienceForm">
        <mat-option *ngFor="let audience of obj;" [value]="audience['@id']" >
          {{audience.name}}
        </mat-option>
      </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" *ngIf="categories.length">
    <mat-label>Categories</mat-label>
    <mat-select (selectionChange)=selectedCategory($event) [formControl]="categoryForm">
      <mat-option *ngFor="let category of categories;" [value]="category['@id']" >
        {{category.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>
