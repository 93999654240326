<ng-container *ngIf="api$ | async as collection">
  <table mat-table [dataSource]="audience" >
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"><a [routerLink]="[element.slug]">{{element.name}}</a></td>
    </ng-container>
    <ng-container matColumnDef="slug">
      <th mat-header-cell *matHeaderCellDef> Slug </th>
      <td mat-cell *matCellDef="let element"> {{element.slug}} </td>
    </ng-container>
    <ng-container matColumnDef="order">
      <th mat-header-cell *matHeaderCellDef> Display Order </th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <div class="move-controls">
          <button type="button" mat-mini-fab color="primary" (click)="moveUp(i)" [disabled]="isFirst(i)"><mat-icon>arrow_upward</mat-icon></button>
          <button type="button" mat-mini-fab color="primary" (click)="moveDown(i)" [disabled]="isLast(i)"><mat-icon>arrow_downward</mat-icon></button>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="published">
      <th mat-header-cell *matHeaderCellDef> Published </th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <mat-slide-toggle [checked]="element.published" (click)="togglePublish(i)">Published</mat-slide-toggle>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</ng-container>
