<mat-card *ngIf="dietaryPreference$ | async">
  <mat-card-title>Edit Dietary Preference</mat-card-title>
  <mat-card-content>
    <form [formGroup]="form" (submit)=submit()>
      <form-dietary-preference formControlName="dietaryPreference" [image]="dietaryPreference.image"></form-dietary-preference>
      <mat-card-actions align="end">
        <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">
          <mat-icon aria-hidden="false" aria-label="Edit icon">save</mat-icon> Update
        </button>
        <button mat-raised-button color="warn" type="button" (click)="openDialog()">
          <mat-icon aria-hidden="false" aria-label="Edit icon">delete</mat-icon> Delete
        </button>
      </mat-card-actions>
    </form>
  </mat-card-content>
</mat-card>
