import { Component, OnInit, OnDestroy, Input, forwardRef, ChangeDetectorRef } from '@angular/core';
import { NgControl, FormControl, FormBuilder, FormGroup, FormArray, Validator, Validators, ControlValueAccessor, AbstractControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { HydraCollection } from '@interface/hydra/collection.interface';
import { Category } from '@interface/category.interface';
import { CategoryService } from '@service/api/category.service';


@Component({
  selector: 'form-category-options',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormCategoryOptionsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormCategoryOptionsComponent),
      multi: true
    }
  ]
})
export class FormCategoryOptionsComponent implements ControlValueAccessor, OnDestroy, Validator {


  constructor(
    private fb: FormBuilder,
    private categoryApi: CategoryService,
  ) {
    this.onChangeSubs.push(this.form.valueChanges.subscribe((value) => {
      this.onChanged(this.getValues(value.categories));
      this.onTouched();
    }))
  }

  form:FormGroup = this.fb.group({
    'categories': this.fb.array([])
  })

  selected: String[] = [];
  categories:Category[] = [];
  categories$:Observable<Category[]> = this.categoryApi.getAll({'pagination': false}).pipe(
    map((data:HydraCollection): Category[] => {
      return data['hydra:member'];
    }),
    tap((data:Category[]) => {
      this.categories = data;
      data.forEach((element, index) => {

        let ctrl = this.getControl('categories');
        ctrl.push(new FormControl(this.selected.includes(element['@id'] as string)));
      });
    })
  );

  onTouched: Function = () => {};
  onChanged: Function = () => {};

  onChangeSubs: Subscription[] = [];

  getValues(values:Boolean[]) {
    let arr: String[] = [];
    values.forEach((element, index) => {
      if(element)
        arr.push(this.categories[index]['@id'] as string)
    });

    return arr;
  }
  ngOnDestroy(): void {
    for (let sub of this.onChangeSubs) {
      sub.unsubscribe();
    }
  }

  getControl(control: string): FormArray {
    return this.form.get(control) as FormArray;
  }
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.form.disable();
    }
    else {
      this.form.enable();
    }
  }

  writeValue(value: any): void {
    if (value) {
      this.selected = value;
    }
  }

  validate(control: AbstractControl) {
    return this.form.valid ? null : { valid: false, message: 'Invalid categories' };
  }

}
