import { Component, OnInit } from '@angular/core';
import { MenuItemService } from '@service/api/menuitem.service';
import { MenuItem } from '@interface/menuitem.interface';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HydraCollection } from '@interface/hydra/collection.interface';
import { MatTable } from '@angular/material/table';


@Component({
  selector: 'admin-menu-item-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class AdminMenuItemListComponent {
  api$:Observable<HydraCollection> = this.api.getAll();
  index: number = 0;
  displayedColumns: string[] = ['name', 'description']

  constructor(public api: MenuItemService) { }

  handlePage(event:any) {
    this.index = event.pageIndex;
    this.api$ = this.api.getAll({
      page: this.getPage()
    });
  }

  getPage(): number {
    return this.index+1;
  }
  getIndex(): number {
    return this.index;
  }
}
