import { Injectable } from '@angular/core';
import { ApiService } from '@service/api/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap, shareReplay, switchMap } from 'rxjs/operators';
import { HydraMember } from '@interface/hydra/member.interface';
import { MealSet } from '@interface/mealset.interface';

@Injectable({
  providedIn: 'root'
})
export class MealSetService extends ApiService {
  endpoint: string = '/meal-sets';
  constructor(
    protected http: HttpClient
  ) {
    super(http);
    this.setEndpoint(this.endpoint);
  }

  create<MealSet>(obj:any): Observable<MealSet> {
    let o = super.create(obj);
    return o.pipe(
      switchMap((x:any) => {
        let formData = new FormData();
        let ele = document.getElementById('image') as HTMLInputElement;
        let file:File|null = null;

        if (ele.files && ele.files.length > 0) {
          let file = ele.files[0];
          formData.append('file', file);
          return this.http.post(x['@id']+'/image', formData)
        }
        return o;
      })
    )
  }

  update<T extends HydraMember>(obj:any): Observable<T> {
    if(obj['@id']) {
      let o = super.update(obj);
      return o.pipe(
        switchMap((x:any) => {
          let formData = new FormData();
          let ele = document.getElementById('image') as HTMLInputElement;
          let file:File|null = null;

          if (ele && ele.files && ele.files.length > 0) {
            let file = ele.files[0];
            formData.append('file', file);
            return this.http.post(x['@id']+'/image', formData)
          }
          return o;
        })
      )
    }
    return of(obj as T);
  }
}
