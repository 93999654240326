import { Component, OnInit, OnDestroy, Input, forwardRef, ChangeDetectorRef } from '@angular/core';
import { NgControl, FormControl, FormBuilder, FormGroup, FormArray, Validator, Validators, ControlValueAccessor, AbstractControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'form-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormUserComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormUserComponent),
      multi: true
    }
  ]
})
export class FormUserComponent implements ControlValueAccessor, OnDestroy, Validator {

  roles: string[] = ['ROLE_ADMIN', 'ROLE_EDITOR', 'ROLE_USER']
  constructor(
    private fb: FormBuilder,
  ) {}

  form: FormGroup = this.fb.group({
    username: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(45)
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(45)
    ]),/*
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(45)
    ]),*/
    roles: new FormArray([])
  });

  onCheckChange($event:any) {

  }

  onTouched: Function = () => {};
  onChanged: Function = () => {};

  onChangeSubs: Subscription[] = [];


  ngOnDestroy(): void {
    for (let sub of this.onChangeSubs) {
      sub.unsubscribe();
    }
  }

  registerOnChange(fn: any): void {
     this.onChangeSubs.push(this.form.valueChanges.subscribe(fn));
   }
   registerOnTouched(fn: any): void {
     this.onTouched = fn;
   }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.form.disable();
    }
    else {
      this.form.enable();
    }
  }

  writeValue(value: any): void {
    if (value) {
      value.roles.forEach((element:string) => {
        this.addRole(element);
      });
      
      this.form.setValue(value, {emitEvent: false});
    }
  }

  validate(control: AbstractControl) {
    return this.form.valid ? null : { valid: false, message: 'Invalid User' };
  }

  addRole(role:string) {
    const roles = this.form.get('roles') as FormArray;
    roles.controls.push(new FormControl(role));
  }


  toggleChange(role:string):void {
    const roles = (this.form.controls.roles as FormArray).controls;
    const index = roles.findIndex(x => { return x.value == role});
    if(index>=0) {
      roles.splice(index, 1);
    } else {
      this.addRole(role);
    }
    (this.form.get('roles') as FormArray).updateValueAndValidity({ onlySelf: false, emitEvent: true })
  }

  isChecked(role:string):boolean {
    if(this.form.value.roles.find((x:string) => x == role)) {
      return true;
    }
    return false;
  }

  isValid(name: string): boolean {
    if(this.form.get(name)) {
      let control = this.form.get(name) as FormControl;
      return control.valid;
    }

    return false;
  }
}
