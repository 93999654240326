<div class="login-wrap container">

  <form [formGroup]="form" (submit)="submit()">
    <img src="/assets/images/catering-logo.svg" />
    <p *ngIf="error">Invalid username or password</p>
    <mat-form-field appearance="fill">
      <mat-label>Username</mat-label>
      <input matInput #input maxlength="45" formControlName="username">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Password</mat-label>
      <input matInput type="password"  formControlName="password">
    </mat-form-field>
    <button>Login</button>
  </form>
</div>
