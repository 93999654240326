import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';


import { HomepageComponent } from '@module/catering-site/components/homepage/homepage.component';
import { AboutComponent } from '@module/catering-site/components/about/about.component';
import { GalleryComponent } from '@module/catering-site/components/gallery/gallery.component';
import { ContactComponent } from '@module/catering-site/components/contact/contact.component';

/* Menu */

import { AudienceListComponent } from '@module/catering-site/components/menu/audience-list/audience-list.component';
import { AudienceViewComponent } from '@module/catering-site/components/menu/audience-view/audience-view.component';
import { AudienceViewResolver } from '@service/resolvers/audience-view.resolver';



const routes: Routes = [
  { path: 'admin', loadChildren: () => import('@module/admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule) },
  { path: '', loadChildren: () => import('@module/catering-site/catering-site.module').then(m => m.CateringSiteModule) },

  //{ path: 'menu', loadChildren: () => import('@module/catering-menu/catering-menu.module').then(m => m.CateringMenuModule) },
  //{ path: '**', loadChildren: () => import('@module/not-found/not-found.module').then(m => m.NotFoundModule) },
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
  onSameUrlNavigation: "reload"
  //enableTracing: true,
  //onSameUrlNavigation: 'reload'
};


@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
