import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminDashboardComponent } from './admin-dashboard.component';

import { AdminMenuComponent } from '@admin/component/menu/menu.component';

/* Audience */
import { AdminAudienceListComponent } from '@admin/component/audience/list/list.component';
import { AdminAudienceCreateComponent } from '@admin/component/audience/create/create.component';
import { AdminAudienceEditComponent } from '@admin/component/audience/edit/edit.component';
import { AdminAudienceIndexComponent } from '@admin/component/audience/index/index.component';
import { AudienceResolver } from '@service/resolvers/audience.resolver';

/* Audience Category */

import { AdminAudienceCategoryListComponent } from '@admin/component/audience-category/list/list.component';
import { AdminAudienceCategoryCreateComponent } from '@admin/component/audience-category/create/create.component';
import { AdminAudienceCategoryEditComponent } from '@admin/component/audience-category/edit/edit.component';
import { AdminAudienceCategoryIndexComponent } from '@admin/component/audience-category/index/index.component';
import { AudienceCategoryResolver } from '@service/resolvers/audience-category.resolver';


/* Meal Set */
import { AdminMealSetListComponent } from '@admin/component/meal-set/list/list.component';
import { AdminMealSetCreateComponent } from '@admin/component/meal-set/create/create.component';
import { AdminMealSetEditComponent } from '@admin/component/meal-set/edit/edit.component';
import { AdminMealSetIndexComponent } from '@admin/component/meal-set/index/index.component';
import { MealSetResolver } from '@service/resolvers/meal-set.resolver';
import { MealSetEditResolver } from '@service/resolvers/meal-set-edit.resolver';


/* Allergy */
import { AdminAllergyListComponent } from '@admin/component/allergy/list/list.component';
import { AdminAllergyCreateComponent } from '@admin/component/allergy/create/create.component';
import { AdminAllergyEditComponent } from '@admin/component/allergy/edit/edit.component';
import { AdminAllergyIndexComponent } from '@admin/component/allergy/index/index.component';
import { AllergyResolver } from '@service/resolvers/allergy.resolver';
import { AudienceCategoryEditResolver } from '@service/resolvers/audience-category-edit.resolver';



/* Category */
import { AdminCategoryListComponent } from '@admin/component/category/list/list.component';
import { AdminCategoryCreateComponent } from '@admin/component/category/create/create.component';
import { AdminCategoryEditComponent } from '@admin/component/category/edit/edit.component';
import { AdminCategoryIndexComponent } from '@admin/component/category/index/index.component';
import { CategoryResolver } from '@service/resolvers/category.resolver';

/* DietaryPreference */

import { AdminDietaryPreferenceListComponent } from '@admin/component/dietary-preference/list/list.component';
import { AdminDietaryPreferenceCreateComponent } from '@admin/component/dietary-preference/create/create.component';
import { AdminDietaryPreferenceEditComponent } from '@admin/component/dietary-preference/edit/edit.component';
import { AdminDietaryPreferenceIndexComponent } from '@admin/component/dietary-preference/index/index.component';
import { DietaryPreferenceResolver } from '@service/resolvers/dietary-preference.resolver';

/* Menu Item */
import { AdminMenuItemListComponent } from '@admin/component/menu-item/list/list.component';
import { AdminMenuItemIndexComponent } from '@admin/component/menu-item/index/index.component';
import { AdminMenuItemEditComponent } from '@admin/component/menu-item/edit/edit.component';
import { MenuItemResolver } from '@service/resolvers/menuitem.resolver';
import { AdminMenuItemCreateComponent } from '@admin/component/menu-item/create/create.component';


/* User */
import { AdminUserListComponent } from '@admin/component/user/list/list.component';
import { AdminUserCreateComponent } from '@admin/component/user/create/create.component';
import { AdminUserEditComponent } from '@admin/component/user/edit/edit.component';
import { AdminUserIndexComponent } from '@admin/component/user/index/index.component';
import { UserResolver } from '@service/resolvers/user.resolver';

import { AuthGuardService } from './guard/auth.service';

import { LoginComponent } from '@admin/component/login/login.component';
import { LogoutComponent } from '@admin/component/logout/logout.component';

import { AdminInquiryIndexComponent } from '@admin/component/inquiry/index/index.component';
import { AdminInquiryViewComponent } from '@admin/component/inquiry/view/view.component';
import { InquiryResolver } from '@service/resolvers/inquiry.resolver';


const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuardService],
    data: {
      roles: ['ROLE_ADMIN', 'ROLE_EDITOR']
    },
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: '',
    component: AdminDashboardComponent,
    canActivate: [AuthGuardService],
    data: {
      roles: ['ROLE_ADMIN', 'ROLE_EDITOR']
    },
    
    children: [
      {
        path: '',
        component: AdminInquiryIndexComponent,
      },
      {
        path: 'user',
        children: [
          {
            path: '',
            component: AdminUserIndexComponent
          },
          {
            path: 'list',
            component: AdminUserListComponent
          },
          {
            path: 'edit/:id',
            component: AdminUserEditComponent,
            resolve: {
              data: UserResolver,
            },
          },
          {
            path: 'create',
            component: AdminUserCreateComponent
          }
        ]
      },
      {
        path: 'inquiry',
        component: AdminInquiryIndexComponent,
      }, {
        path: 'inquiry/:id',
        component: AdminInquiryViewComponent,
        resolve: {
          data: InquiryResolver,
        },
      },
      {
        path: 'menu',
        component: AdminMenuComponent,
        children: [
          {
            path: 'audience',
            children: [
              {
                path: '',
                component: AdminAudienceIndexComponent
              },
              {
                path: 'create',
                component: AdminAudienceCreateComponent
              },
              {
                path: 'list',
                component: AdminAudienceListComponent
              },
              {
                path: ':id',
                component: AdminAudienceEditComponent,
                resolve: {
                  data: AudienceResolver,
                },
              },
              {
                path: ':id/:category',
                component: AdminAudienceCategoryEditComponent,
                resolve: {
                  data: AudienceCategoryEditResolver,
                }
              },
              {
                path: ':id/:category/meal-set/create',
                component: AdminMealSetCreateComponent,
                resolve: {
                  data: AudienceCategoryEditResolver,
                }
              },
              {
                path: ':id/:category/meal-set/:mealsetId',
                component: AdminMealSetEditComponent,
                resolve: {
                  data: MealSetEditResolver,
                }
              }
            ]
          },
          {
            path: 'audience-category',
            children: [
              {
                path: '',
                component: AdminAudienceCategoryIndexComponent
              },
              {
                path: 'list',
                component: AdminAudienceCategoryListComponent
              },
              {
                path: 'edit/:id',
                component: AdminAudienceCategoryEditComponent,
                resolve: {
                  data: AudienceCategoryResolver,
                },
              },
              {
                path: 'create',
                component: AdminAudienceCategoryCreateComponent
              },
            ]
          },
          {
            path: 'allergy',
            children: [
              {
                path: '',
                component: AdminAllergyIndexComponent
              },
              {
                path: 'list',
                component: AdminAllergyListComponent
              },
              {
                path: 'edit/:id',
                component: AdminAllergyEditComponent,
                resolve: {
                  data: AllergyResolver,
                },
              },
              {
                path: 'create',
                component: AdminAllergyCreateComponent
              }
            ]
          },
          {
            path: 'category',
            children: [
              {
                path: '',
                component: AdminCategoryIndexComponent
              },
              {
                path: 'list',
                component: AdminCategoryListComponent
              },
              {
                path: 'edit/:id',
                component: AdminCategoryEditComponent,
                resolve: {
                  data: CategoryResolver,
                },
              },
              {
                path: 'create',
                component: AdminCategoryCreateComponent
              }
            ]
          },
          {
            path: 'dietary-preference',
            children: [
              {
                path: '',
                component: AdminDietaryPreferenceIndexComponent
              },
              {
                path: 'list',
                component: AdminDietaryPreferenceListComponent
              },
              {
                path: 'edit/:id',
                component: AdminDietaryPreferenceEditComponent,
                resolve: {
                  data: DietaryPreferenceResolver,
                },
              },
              {
                path: 'create',
                component: AdminDietaryPreferenceCreateComponent
              }
            ]
          },
          {
            path: 'menu-item',
            children: [
              {
                path: 'list',
                component: AdminMenuItemListComponent
              },

              {
                path: '',
                component: AdminMenuItemIndexComponent
              },
              {
                path: 'edit/:id',
                component: AdminMenuItemEditComponent,
                resolve: {
                  data: MenuItemResolver,
                },
              },
              {
                path: 'create',
                component: AdminMenuItemCreateComponent
              }
            ]
          },
          {
            path: 'meal-set',
            children: [

              {
                path: 'list',
                component: AdminMealSetListComponent
              },

              {
                path: '',
                component: AdminMealSetIndexComponent
              },
              {
                path: 'edit/:id',
                component: AdminMealSetEditComponent,
                resolve: {
                  data: MealSetResolver,
                },
              },
              {
                path: 'create',
                component: AdminMealSetCreateComponent
              }
            ]
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminDashboardRoutingModule { }
