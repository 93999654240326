import { Component, OnInit, OnDestroy, Input, forwardRef, ChangeDetectorRef } from '@angular/core';
import { NgControl, FormControl, FormBuilder, FormGroup, FormArray, Validator, Validators, ControlValueAccessor, AbstractControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Image } from '@interface/image.interface';


@Component({
  selector: 'form-audience',
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormAudienceComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormAudienceComponent),
      multi: true
    }
  ]
})
export class FormAudienceComponent implements ControlValueAccessor, OnInit, OnDestroy, Validator {


  constructor(
    private fb: FormBuilder
  ) {

  }

  ngOnInit() {
    if(this.image)
      this.image_path = this.image.jpg;
  }

  @Input() image!: Image;
  @Input() image_required: boolean = false;

  image_max_size:number = 500000;
  image_path: string = '';
  image_width:number = 560;
  image_height:number = 674;
  file_errors = this.resetErrors();
  input_touched: boolean = false;

  form: FormGroup = this.fb.group({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(45)
    ]),
    description: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(255)
    ]),
    displayOrder: new FormControl(this.getDisplayOrder()),
    published: new FormControl(false, [
      Validators.required
    ]),
    slug: new FormControl('', [Validators.required])
  });

  onTouched: Function = () => {};
  onChanged: Function = () => {};

  onChangeSubs: Subscription[] = [];

  ngOnDestroy(): void {
    for (let sub of this.onChangeSubs) {
      sub.unsubscribe();
    }
  }
  getDisplayOrder(): Number {
    const n = new Date();
    return n.getFullYear() + n.getMonth() + n.getDay() + n.getHours() + n.getMinutes() + n.getSeconds();
  }

  registerOnChange(fn: any): void {
   this.onChangeSubs.push(this.form.valueChanges.subscribe(fn));
  }
  registerOnTouched(fn: any): void {
   this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.form.disable();
    }
    else {
      this.form.enable();
    }
  }

  writeValue(value: any): void {
    if (value) {
      this.form.setValue(value, {emitEvent: false});
    }
  }

  validate(control: AbstractControl) {
    if(this.image_required) {
      if(!this.image_path) {
        this.file_errors.required = true;
        return { valid: false, message: 'Invalid audience' };
      }
    }
    return this.form.valid ? null : { valid: false, message: 'Invalid audience' };
  }

  isValid(name: string): boolean {

    if(this.form.get(name)) {
      let control = this.form.get(name) as FormControl;
      return control.valid;
    }

    return false;
  }

  isTouched(name: string): boolean {

    if(this.form.get(name)) {
      let control = this.form.get(name) as FormControl;
      return control.dirty;
    }

    return false;
  }
  resetErrors() {
    return {
      image_large: false,
      required: false,
      image_dims: false,
    };
  }

  getErrors() {
    let arr = Object.entries(this.file_errors);
    return arr.filter(([key, value]) => value).length
  }

  change(e:any) {
    this.input_touched = true;
    this.file_errors = this.resetErrors();

    let file = e.target.files[0];
    if( file.size > this.image_max_size)
      this.file_errors.image_large=true;

    let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        const image = new Image();
        image.src = _event!.target!.result as string;
        image.onload = () => {
          if(image.width !== this.image_width || image.height !== this.image_height)
            this.file_errors.image_dims = true;

          if(!this.getErrors()) {
            this.image_path = reader.result as string;
            this.form.updateValueAndValidity();
          }
        };
      }
    }
}
