import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, mergeMap, first, catchError } from 'rxjs/operators';

import { User } from '@interface/user.interface';

@Injectable({
  providedIn: 'root'
})
export class LoginService {


  constructor(
    private http: HttpClient
  ) { }


  doLogin(username:string, password:string) {
    return this.http.post<User>('/api/login', {
      'username': username,
      'password': password,
    }, {
      observe: 'response',
      withCredentials: true
    }).pipe(
      mergeMap((data:any) => {
        return this.http.get(data.headers.get('Location'))
      })
    );
  }

  whoAmI() {
    return this.http.get('/api/login', {
      observe: 'response',
      withCredentials: true
    }).pipe(
      mergeMap((data:any):Observable<User> => {
        return this.http.get<User>(data.headers.get('Location'))
      }), catchError((data:any) => {
          return of(false);
      })
    )
  }

  doLogout() {
    return this.http.get('/api/logout');
  }

}
