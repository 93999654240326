import { Component, OnInit } from '@angular/core';
import { MealSetService } from '@service/api/mealset.service';
import { MealSet } from '@interface/mealset.interface';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HydraCollection } from '@interface/hydra/collection.interface';


@Component({
  selector: 'admin-mealSet-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class AdminMealSetListComponent {
  api$:Observable<HydraCollection> = this.api.getAll();
  index: number = 0;

  constructor(public api: MealSetService) { }

  handlePage(event:any) {
    this.index = event.pageIndex;
    this.api$ = this.api.getAll({
      page: this.getPage()
    });
  }

  getPage(): number {
    return this.index+1;
  }
  getIndex(): number {
    return this.index;
  }


}
