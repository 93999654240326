<fieldset [formGroup]="form">
  <mat-form-field appearance="outline" class="full" *ngFor="let key of keys">
    <mat-label>{{key.name}}</mat-label>
    <input matInput type="number" [formControlName]="key.id" [id]="key.id" required>
    <mat-error *ngIf="!isValid(key.id) && form.get(key.id)?.errors as errors">
      <div *ngIf="errors.required">This field is required</div>
      <div *ngIf="errors.server">{{errors.server}}</div>
    </mat-error>
  </mat-form-field>
</fieldset>
